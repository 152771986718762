@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.logos {
    overflow: hidden;
    padding: 8px 0;
    background: transparent;
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
}

.logos-slide img {
    height: 47px;

    width: 101px !important;
    margin: 0 20px;
}

.carousel .control-dots {
    bottom: -20px !important;
}
.carousel .control-dots .dot {
    margin-top: 10px !important;
    background: #ff8788 !important;
    box-shadow: none !important;
}

.carousel.carousel-slider {
    overflow: visible !important;
}

.slick-dots {
    li {
        height: 12px;
        width: 12px;
        margin: 0 3px;

        @media screen and (max-width: 820px) {
            height: 6px;
            width: 6px;
            margin: 0 1.5px;
        }
    }
}
.slick-dots .slick-active {
    background-color: #ff8788;
    width: 24px !important;
    height: 12px;
    border-radius: 8px;

    @media screen and (max-width: 820px) {
        width: 12px !important;
        height: 6px;
    }
}
