//SIDENAV
//author: @Anisat Yunusa

.settings__sidenav {
    position: fixed;
    z-index: 1;
    top: 40px;
    width: calc(100% - 168px);
    border: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #ffffff;
    @media screen and (max-width: 520px) {
        width: calc(100% - 48px);
    }

    h3 {
        display: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0.004em;

        @media screen and (max-width: 820px) {
            display: block;
            margin-top: 66px;
            margin-bottom: 20px;
        }
    }

    ul {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: scroll;
        margin-top: 80px;

        @media screen and (max-width: 820px) {
            width: 100%;
            margin-top: unset;
        }
        @media screen and (max-width: 520px) {
            border: 2px solid #fff3f3;
            border-radius: 8px 8px 0px 0px;
        }

        li {
            align-self: flex-end;
            padding: 0.75rem 1rem;
            color: $gray;
            font-weight: 500;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 24px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            text-align: center;

            @media screen and (max-width: 820px) {
                flex: 1;
                font-size: 12px;
            }

            cursor: pointer;
            &.active {
                background: lighten($color: $primary, $amount: 22%);
                color: $secondary;
                border-bottom: 4px solid $primary;
            }
        }
    }
}
