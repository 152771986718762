.tax {
    margin-top: 1rem;
    &__container {
        margin: 30px;
        border: 1px solid rgba(211, 211, 211, 0.6);
        border-radius: 12px;
        padding: 0 30px;
        padding-bottom: 30px;
        @media #{ $mediumdown } {
            border: none;
        }
        @media #{ $smalldown } {
            padding: 0;
            margin: 16px;
        }
        > h1 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 35.2px;
            text-align: center;
            text-transform: capitalize;
            padding-top: 30px;
            padding-bottom: 50px;
            @media #{ $mediumdown } {
                font-size: 18px;
                line-height: 24.2px;
                padding-top: 6px;
                padding-bottom: 20px;
            }
        }
        &--cards {
            width: 100%;
            position: relative;
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            grid-gap: 1.25rem;
            margin-bottom: 50px;

            @media #{ $smalldown } {
                margin-bottom: 20px;
            }

            @media #{ $mediumup } {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem;
            }

            @media screen and (min-width: 1250px) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 2rem;
            }
            @media screen and (min-width: 1300px) {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 2rem;
            }
        }
        > .info-banner {
            border: 1px dashed #005397;
            margin-top: 50px;
            @media #{ $smalldown } {
                margin-top: 30px;
            }
            > svg {
                margin-top: 0px;
            }
            p {
                font-size: 1rem;
                font-weight: 500;
                line-height: 19.36px;
                text-align: left;
                color: #005397;
            }
        }
        &--card {
            border: 1px solid rgba(211, 211, 211, 0.6);
            padding: 30px;
            border-radius: 12px;
            @media #{ $smalldown } {
                padding: 20px;
            }
            .img--container {
                width: 130px;
                height: 130px;
                margin: 0 auto;
                @media #{ $smalldown } {
                    width: 50px;
                    height: 50px;
                }

                > img {
                    width: 100%;
                    height: 100%;
                }
            }
            .card--body {
                h3 {
                    margin-top: 6px;
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 30px;
                    color: $black;
                    @media #{ $smalldown } {
                        font-size: 1rem;

                        line-height: 24px;
                    }
                }

                p {
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 19.6px;
                    text-align: center;
                    color: #5c6368;
                    margin-top: 16px;
                    @media #{ $smalldown } {
                        font-size: 0.875rem;

                        line-height: 19.6px;
                        margin-top: 6px;
                    }
                    a {
                        text-decoration: underline;
                        color: #5c6368;
                    }
                }
            }
        }
    }
}
