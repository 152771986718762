.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .close {
        visibility: hidden;
    }
    .open {
        visibility: visible;
    }
}
.tooltiptext {
    // visibility: hidden;
    width: 320px;
    background-color: rgba(248, 252, 255, 1);
    border-radius: 8px;
    box-shadow: 0px 20px 25px -5px rgba(51, 65, 85, 0.1);

    border-radius: 6px;
    padding: 5px 0;
    padding: 18px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -70px;
    @media #{ $largedown } {
        right: 0;
    }
    @media #{ $xs-md-down } {
        width: 300px;
    }
    // margin-left: -60px;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // @media #{ $smalldown } {
        //     align-items: baseline;
        // }
        &--text {
            display: flex;
            align-items: center;
            gap: 10px;
            // @media #{ $smalldown } {
            //     align-items: baseline;
            // }
            > p {
                font-size: 1rem;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #000b14;
            }
        }
        > svg {
            opacity: 0.3;
        }
    }
    &__body {
        margin-top: 18px;
        color: #5c6368;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
}
// .tooltip:hover .tooltiptext {
//     visibility: visible;
// }
