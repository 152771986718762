.main {
    display: flex;
    flex-direction: column;

    &__right {
        width: 100%;
        margin-left: auto;

        @media screen and (min-width: 820px) {
            width: calc(100% - 247px);
        }
    }

    &__body {
        padding: 24px;
        padding-bottom: 100px;
        max-width: 1480px;
        margin: auto;

        @media #{$smallup} {
            padding-left: 144px;
        }

        @media screen and (min-width: 820px) {
            padding-left: 24px;
        }

        @media screen and (min-width: 1020px) {
            padding: 2.5rem;
        }
    }

    &-bg-secondary {
        color: $secondary;
    }

    &-container {
        position: relative;
        max-width: 1150px;
        width: 100%;
        // margin: auto;
    }
}

.nonsidebar {
    &__body {
        padding: 24px;
        padding-bottom: 100px;
        max-width: 1250px;
        margin: auto;
        width: 100%;

        @media screen and (min-width: 820px) {
            padding: 24px 2.5rem 100px;
        }
    }
}

.boltPreHomeMain {
    background: $primary-light-2 url("../../assets/svgs/pttrn.svg") right bottom
        no-repeat;
    @media #{ $largedown } {
        background-size: 35%;
    }
    @media #{ $mediumdown } {
        background-size: 65%;
    }
}
