//PLAN DETAILS
//author: @Anisat Yunusa

.planDeets {
    &--history {
        position: relative;
        margin-top: 4rem;
    }

    .mobileview-table {
        .tablelist_data {
            @media #{ $xsmalldown } {
                display: block;
            }
        }
    }
}

//Insurance details
.insurance--details {
    position: relative;

    &-title {
        display: block;
        font-size: 1rem;
        margin-top: 0px;
        @media screen and (min-width: 820px) {
            display: none;
        }
    }

    &__overview {
        position: relative;

        &--header {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 1rem;
            @media #{ $smalldown } {
                row-gap: 1.5rem;
            }

            .section--header {
                margin-right: 1rem;
            }
            .section--btn {
                width: 273px;
                @media #{ $mediumdown } {
                    width: 100%;
                }
            }

            .btn-group {
                position: relative;
                .btn--secondary {
                    @media #{ $smalldown } {
                        display: none;
                    }
                }
                .btn {
                    border-radius: 4px;
                    &:first-child {
                        margin-right: 1rem;
                    }

                    @media screen and (max-width: 380px) {
                        width: 100%;
                        &:first-child {
                            margin-right: 0;
                        }
                        &:last-child {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        &--main {
            position: relative;
            margin-top: 2rem;

            @media #{ $largeup } {
                display: flex;
            }

            .card-balance {
                min-height: 190px;

                @media #{ $largeup } {
                    width: 360px;
                }
            }

            .policy--details {
                @extend .card-white;
                margin-top: 2rem;

                @media #{ $largeup } {
                    flex: 1;
                    margin-top: 0;
                    margin-left: 2rem;
                }

                @media screen and (min-width: 1024px) {
                    min-height: unset;
                }

                .__top {
                    position: relative;
                    width: inherit;
                    padding: 1rem 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 30px;
                    flex-wrap: wrap;
                    border: 1px solid $off-white;
                    p {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 17px;
                        text-transform: uppercase;
                    }

                    .__left {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .__hospital {
                        position: relative;
                        margin-right: 0.625rem;
                        flex: 1;
                        @media #{ $mediumup } {
                            text-align: right;
                        }

                        &-title {
                            position: relative;
                            font-weight: 500;
                            font-size: 11px;
                            color: #5c6368;
                            .btn {
                                font-size: 0.75rem;
                            }
                        }

                        &-bottom {
                            position: relative;
                            display: inline-flex;
                            align-items: baseline;
                            flex-wrap: wrap;
                            gap: 0.75rem;
                            @media #{ $mediumup } {
                                flex-direction: row-reverse;
                            }
                        }

                        &-value {
                            position: relative;
                            font-weight: 500;
                            font-size: 0.875rem;
                            line-height: 21px;
                            text-transform: capitalize;
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media #{ $mediumdown } {
                                margin-top: 0.5rem;
                            }
                        }
                    }
                }

                .__deets {
                    position: relative;
                    padding: 24px 1.5rem;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 30px;
                    margin-top: auto;

                    &--info {
                        position: relative;

                        @media #{ $mediumup } {
                            margin-right: 1rem;
                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        .title {
                            position: relative;
                            color: $gray;
                            font-size: 0.75rem;
                            font-weight: 600;
                            line-height: 20px;
                            text-transform: uppercase;
                        }

                        .value {
                            position: relative;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 20px;
                            margin-top: 0.75rem;
                        }
                    }

                    &--action {
                        padding: 0px 1.5rem 24px 1.5rem;
                        display: flex;
                        justify-content: end;
                        width: 100%;
                        align-items: center;

                        .btn--sm {
                            margin-left: 30px;
                        }

                        .btn--secondary {
                            width: 167px;
                        }
                    }
                }
            }
        }
    }
}

//savings
.savings--details {
    position: relative;

    .info-banner {
        background: $pending-light;

        svg {
            color: $pending;
        }
    }

    &__overview--main {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }

        @media #{ $largeup } {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .stats-card__main {
        .__group {
            @media #{ $smalldown } {
                flex-direction: column;
                align-items: unset;
                .__value--yield {
                    margin: 0;
                    margin-top: 1rem;
                }
            }
        }
    }
}

// gym
.gym--details__overview--main {
    .gym__details {
        display: flex;
        width: 100%;
        gap: 1.5rem;
        // flex-direction: row;
        @media #{ $largedown } {
            flex-direction: column-reverse;
        }
        @media #{ $smalldown } {
            flex-direction: column;
        }
        > .benefit__info {
            width: 100%;
            border-radius: 0.625rem;
            border: 1px solid #f5f5f5;
            background: #fff;
            padding: 1.5rem;

            @media #{ $mediumdown } {
                margin-bottom: 40px;
            }
            .info--header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px dashed rgba(92, 99, 104, 0.4);
                padding-bottom: 1rem;
                @media #{ $largedown } {
                    flex-direction: column;
                    gap: 20px;
                }
                .provider {
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    .img__container {
                        width: 3rem;
                        height: 3rem;
                        border-radius: 6.25rem;
                        background: #000b14;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // padding: 0.3rem;
                        @media #{ $smalldown } {
                            width: 2rem;
                            height: 2rem;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 6.35rem;
                        }
                    }
                    .name {
                        > p {
                            color: #000b14;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            @media #{ $smalldown } {
                                font-size: 14px;
                            }
                        }
                        > span {
                            color: #e0063c;
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            @media #{ $smalldown } {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .btn__groups {
                    display: flex;
                    gap: 1rem;
                    .btn {
                        background-color: #f5f5f5;
                    }
                    .cancel {
                        color: #e0063c;
                    }
                    .pause {
                        color: #3d0814;
                    }
                }
            }
            .info--body {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-top: 1.5rem;
                @media #{ $largedown } {
                    flex-wrap: wrap;
                }
                > .deets {
                    @media #{ $largedown } {
                        margin-top: 1.5rem;
                    }
                    h4 {
                        color: #5c6368;
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem; /* 166.667% */
                        text-transform: uppercase;
                        margin-bottom: 1rem;
                    }
                    p {
                        color: #000b14;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.25rem;
                    }
                    span {
                        padding: 0.25rem 1rem;
                        align-items: center;
                        gap: 0.625rem;

                        border-radius: 6.25rem;
                        font-size: 0.7rem;
                        font-weight: 700;
                    }
                    .active {
                        background: #ebf7ee;
                        color: #236f00;
                    }
                    .expired {
                        color: #6f0000;
                        background: #fff3f4;
                    }
                    .inactive {
                        background: #f5f5f5;
                        color: #5f6062;
                    }
                }
            }
        }
        > .explore__card {
            border-radius: 0.625rem;
            border: 1px solid #f5f5f5;
            display: flex;
            background: var(--f-coral-light-1, #fff3f3);
            padding: 1.5rem;
            max-width: 350px;
            height: fit-content;
            align-items: center;
            .container--text {
                > h2 {
                    color: #000b14;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                > p {
                    color: #5c6368;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                    padding-top: 0.5rem;
                    padding-bottom: 1.5rem;
                }
                .btn {
                    width: 100%;
                }
            }
        }
    }

    .service__provider {
        @media #{ $mediumdown } {
            margin-top: 10px !important;
        }
        &--container {
            display: flex;
            gap: 25px;
            @media #{ $mediumdown } {
                flex-direction: column;
            }
        }
        border-radius: 0.75rem;
        border: 1px solid #f5f5f5;
        background: #fff;
        width: 100%;
        &--header {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
            height: 56px;
            border-bottom: 1px solid #f5f5f5;
            background-color: #fff3f3;
            > h1 {
                color: #000b14;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
            }
            .provider__icons {
                display: flex;
                gap: 1rem;

                .icon {
                    width: 2rem;
                    height: 2rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #fff3f3;
                }
            }
        }
        &--body {
            padding: 1rem 1.5rem;

            p.map > a {
                color: #005397;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem; /* 142.857% */
                text-decoration-line: underline;
                cursor: pointer;
            }
            .container {
                // display: grid;
                // grid-template-columns: repeat(1, 1fr);
                // @media #{ $mediumup } {
                //     grid-template-columns: repeat(2, 1fr);
                //     grid-gap: 2rem;
                // }

                // @media #{ $slaptop } {
                //     grid-template-columns: repeat(3, 1fr);
                // }
                // gap: 2rem;

                .info {
                    display: flex;
                    gap: 6px;
                    margin-bottom: 20px;
                    color: #5c6368;

                    > a {
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        color: #005397;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
                .info-hours {
                    width: 300px;
                    margin-bottom: 20px;
                    > h4 {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        text-align: left;
                        color: #5c6368;
                        text-transform: uppercase;
                        margin-bottom: 6px;
                    }
                    .workingTimes {
                        display: flex;
                        width: 100%;
                        align-items: flex-end;
                        p {
                            width: 50%;
                            display: flex;
                            gap: 6px;
                            text-transform: capitalize;
                            color: #5c6368;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.94px;
                            text-align: left;
                            padding: 9.5px 0;
                            align-items: flex-end;
                        }
                    }
                }
                .offer {
                    margin-bottom: 12px;
                    > h4 {
                        font-size: 0.875rem;
                        text-transform: uppercase;
                        font-weight: 600;
                        line-height: 14.52px;
                        text-align: left;
                        color: #000b14;
                    }
                    p {
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 33.89px;
                        text-align: left;
                        margin-top: 6px;
                        > span {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16.94px;
                            text-align: left;
                        }
                    }
                }
                .offer-list {
                    > p {
                        padding: 6px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        color: #5c6368;
                        display: flex;
                        gap: 14px;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.btn__groups {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 30px;
    .btn {
        background-color: #f5f5f5;
    }
    .cancel {
        color: #e0063c;
    }
    .pause {
        color: #3d0814;
    }
}
