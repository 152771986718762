//ACCOUNT SETUP
//author: @Anisat Yunusa


.account-setup {
    position: relative;

    &__header {
        position: relative;
        max-width: 400px;
        width: 100%;
        margin: auto;
        // margin-top: 5rem;
        margin-top: 2rem;
        text-align: center;


        h3 {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 32px;
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.004em;
            color: $gray;
        }
    }

    &__steps {
        position: relative;
        max-width: 926px;
        width: 100%;
        margin: auto;
        margin-top: 3rem;
    }

    &__step {
        position: relative;
        @extend .card-white;
        width: inherit;
        padding: 1.5rem;

        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        @media #{ $mediumup } {
            padding: 1.5rem 2.5rem;
        }

        &--wrapper {
            width: inherit;

            @media #{ $mediumup } {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &--left {
            position: relative;

            @media #{ $smallup } {
                display: flex;
            }

            img {
                align-self: flex-start;
                width: 48px;
                height: 48px;

                @media #{ $smalldown } {
                    // display: none;
                    margin-bottom: .5rem;
                }
            }

            &>div {
                max-width: 450px;
                margin-left: 1.5rem;
                font-size: 1rem;
                line-height: 24px;
                letter-spacing: 0.004em;

                @media #{ $smalldown } {
                    margin-left: 0;

                }

                h3 {
                    font-size: 1rem;
                    font-weight: 600;
                }

                p {
                    font-weight: 400;
                    color: $gray;
                    @media #{ $smalldown } {
                        font-size: .875rem;
                    }
                }

                .collapsible {
                    position: relative;

                    p[role="button"] {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 17px;
                        color: $f-blue;
                        margin-top: 1rem;
                        cursor: pointer;
                        width: fit-content;
                    }
                }
            }


        }

        &--right {
            margin-top: 1rem;

            @media #{ $smallup } {
                margin-left: 72px;
            }

            @media #{ $mediumup } {
                margin: 0;
            }

            .btn {
                height: 50px;

                @media #{ $smalldown } {
                    width: 100%;
                }
            }

            .step-status {
                position: relative;
                padding: 8px;
                min-width: 100px;
                font-size: .75rem;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.02em;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .collapsibleDiv {
            position: relative;
            background: #F8FCFF;
            border-radius: 4px;
            padding: 1.25rem 1.5rem;
            margin-top: 1rem;
            margin-bottom: .5rem;

            @media #{ $mediumup } {
                margin-left: 72px;
                margin-bottom: 0;
            }

            p {
                position: relative;
                font-size: .875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                color: $black;

                span {
                    font-weight: 500;
                }
            }
        }
    }

    &__btn {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 48px;
    }
}


//PERSONAL INFO
.personalInfo {
    position: relative;


    form {

        // flex-direction: row-reverse;
        // justify-content: flex-end;
        @media #{ $mediumup } {
            display: flex;
        }
    }

    &__avatar {
        position: relative;
        background: $primary-light-1;
        border-radius: 8px;
        width: 250px;
        height: 226px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;

        @media #{ $mediumdown } {
            // background: unset;
            width: 100%;
            height: 200px;
            margin: auto;
            margin-bottom: 2rem;
        }

        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            height: 100%;
            max-height: 100%;
            border-radius: inherit;
        }

        .upload {
            position: relative;
            width: inherit;
            height: inherit;

            input[type='file'] {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            label {
                position: relative;
                min-height: inherit;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
            }

            .title {
                font-size: 1rem;
                font-weight: 500;
                line-height: 20px;
            }

            .subtext {
                font-size: .75rem;
                font-weight: 400;
                line-height: 15px;
                color: $gray;
            }
        }

        .avatar-circle {
            position: relative;
            height: 100px;
            width: 100px;
            margin: 0;

            &__placeholder {
                position: relative;
                height: 96px;
                width: 96px;
                border-radius: 100%;
                background: $primary-light-2;
            }

            &__initials {
                font-size: 1.875rem;
                line-height: 36px;
            }
        }
    }

    .__theForm {
        position: relative;
        max-width: 520px;
        width: 100%;

        @media #{ $mediumup } {
            margin-left: 2rem;
        }

        @media #{ $largeup } {
            margin-left: 4rem;
        }

        &-title {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 22px;
            text-transform: capitalize;
            margin-bottom: 1.25rem;
        }
    }
}

.hospital {
    &__loader {
        width: 16px;
        height: 16px;
        animation-name: ckw;
        animation-duration: 1s;
        /* Things added */
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        display: inline-block;
        /* <--- */

        &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(#F5F5F5, 0.6);
            border-radius: 8px;
            min-height: 60px;
            margin-bottom: 40px;
            padding: .625rem 1.5rem;
            @media #{ $mediumup } {
                padding: 0 1.5rem;
            }
        }

        &-text {
            max-width: 400px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin-right: 12px;

            &.error {
                color: #E0063C;
            }
        }
    }
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}