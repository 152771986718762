// Colors
//author: @Anisat Yunusa

.co-primary {
    color: $primary;
}

.co-gray {
    color: $gray;
}

.co-gray-60 {
    color: rgba($color: $gray, $alpha: 0.6);
}

.co-danger {
    color: $danger;
}

.co-success {
    color: $success;
}

.co-blue {
    color: $f-blue;
}

.co-black {
    color: $black;
}

.bo-success {
    > p {
        background-color: #eafff4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        width: fit-content;
        padding: 6px 12px;
        text-transform: lowercase;
        display: flex;
        gap: 5px;
        border-radius: 20px;
        color: #236f00;
    }
}
.bo-danger {
    > p {
        background-color: #fff3f4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        width: fit-content;
        padding: 6px 12px;
        text-transform: lowercase;
        display: flex;
        gap: 5px;
        border-radius: 20px;
        color: #6f0000;
    }
}
