//Card
//author: @Anisat Yunusa

.modal {
    position: relative;

    &-form {
        .btn--link-secondary {
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
        }
    }

    .__claim-title {
        position: relative;
        line-height: 24px;
    }
}

.modal-withheight {
    .modal-body {
        max-height: 85vh;
        overflow: hidden;
        overflow-y: scroll;
    }

    .modal-container-top {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: inherit;
    }
}

//confirm deets modal
.confirm-deets-modal {
    // position: relative;

    &__title {
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: $gray;

        span {
            text-transform: capitalize;
        }
    }

    &__price-banner {
        position: relative;
        // min-width: 339px;
        width: 100%;
        padding: 1.5rem 1.25rem;
        background-color: $primary-light-1;
        text-align: center;
        border-radius: 4px;

        @media #{ $smalldown } {
            min-width: unset;
            width: 100%;
        }

        .__top--value {
            position: relative;
            font-size: 2rem;
            font-weight: 600;
            line-height: 39px;
            margin-top: 0.625rem;

            @media #{ $smalldown } {
                font-size: 1.75rem;
                line-height: 30px;
            }

            .__period {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                color: $gray;
            }
        }

        .__top--worth {
            padding: 0.5rem 1rem;
            margin-top: 0.5rem;
            font-weight: 500;
            line-height: 20px;
            color: $f-blue;

            @media #{ $smalldown } {
                font-size: 0.875rem;
            }
        }
    }

    &__list {
        position: relative;
        margin-top: 2.5rem;

        &--deets {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            @media #{ $xsmalldown } {
                display: block;
            }

            p {
                font-weight: 500;
                line-height: 20px;

                @media #{ $xsmalldown } {
                    margin-top: 0.875rem;
                }

                &:first-child {
                    color: $gray;

                    @media #{ $xsmalldown } {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .card-type {
        width: 32px;
        height: 24px;
        color: #fff3f3;
    }

    &__terms {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $gray;
        margin-top: 24px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        a {
            color: $gray;
            text-decoration: underline;
        }

        span {
            text-transform: uppercase;
        }
    }
}

.fund-wallet-modal {
    margin-top: 0;

    .copyPaste {
        width: 14px;
        height: 16px;
        color: $f-blue;

        &:hover {
            cursor: pointer;
            color: lighten($color: $f-blue, $amount: 5px);
        }
    }

    span {
        margin-left: 0.75rem;
    }

    .confirm-deets-modal__list--deets {
        @media #{ $smalldown } {
            display: block;

            p {
                margin-top: 0.875rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

//withdraw funds
.withdrawal-modal {
    position: relative;

    .txt-label {
        position: relative;
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
    }

    .confirm-deets-modal__list--deets {
        @media #{ $smalldown } {
            display: block;

            p {
                margin-top: 0.875rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .withdrawal-success {
        .__icon {
            display: flex;
            align-items: center;

            svg {
                margin: auto;
                width: 64px;
                height: 64px;

                @media #{ $smalldown } {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        h3 {
            text-align: center;
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1.5rem;

            @media #{ $smalldown } {
                font-size: 1.25rem;
            }
        }
    }
}

//Verify BVN Modal
.verifyBVN-modal {
    position: relative;

    .modal-form .__title {
        font-size: 0.875rem;
        line-height: 17px;
    }

    .flexed-inputs {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        margin-top: 2rem;

        input {
            display: flex;
            text-align: center;
        }
    }
}

//Add Beneficiary
.add-bene-modal {
    position: relative;

    .personalInfo__avatar {
        width: unset;
        height: unset;
        background: none;

        .avatar-circle {
            width: 100px;
            height: 100px;
        }

        .title {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 20px;
            text-decoration-line: underline;
            text-underline-position: under;
            color: $secondary;
        }
    }
}

//Beneficiaries Modal
.beneficiaries-modal {
    position: relative;

    .search-container {
        position: relative;
        width: 100%;
        display: flex;

        @media screen and (max-width: 380px) {
            flex-direction: column;
        }

        .form-group {
            position: relative;
            display: block;
            flex: 1;

            input {
                width: 100%;
            }
        }

        .btn {
            position: relative;
            width: 100px;
            margin-left: 1rem;

            @media screen and (max-width: 380px) {
                margin: 0;
                width: 100%;
            }
        }
    }

    .beneficiaries-list {
        position: relative;

        @media #{ $smalldown } {
            margin-top: 1.5rem;
        }

        .beneficiary {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0;
            border-bottom: 1px solid $off-white;

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }

            @media screen and (max-width: 380px) {
                display: block;
            }

            &-left {
                position: relative;
                display: flex;
                align-items: center;

                .img-container {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    margin-right: 1rem;

                    @media #{ $smalldown } {
                        display: none;
                    }

                    img {
                        position: relative;
                        border-radius: 100%;
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                        max-height: 100%;
                    }
                }

                .avatar-circle__placeholder {
                    position: relative;
                    border: 1px solid #ff8788;
                    box-sizing: border-box;

                    p {
                        font-size: 1rem;
                    }
                }

                .info {
                    position: relative;

                    h3 {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.004em;
                    }

                    p {
                        font-size: 0.75rem;
                        font-weight: 500;
                        line-height: 15px;
                        color: $gray;

                        @media screen and (max-width: 380px) {
                            font-size: 0.875rem;
                            margin-top: 0.25rem;
                        }
                    }
                }
            }

            &-right {
                position: relative;

                @media screen and (max-width: 380px) {
                    margin-top: 1rem;
                }

                p {
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.004em;
                    color: $gray;
                }
            }
        }
    }

    .no-beneficiaries-list {
        .auth__success {
            display: block;
        }
    }
}

.activation-modal {
    position: relative;

    .__content {
        position: relative;

        .illustration {
            position: relative;
            z-index: 1;
            width: 230px;
            height: auto;
            margin: auto;
            text-align: center;

            div[role="button"] {
                position: absolute;
                z-index: -1;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
            }

            img {
                position: relative;
                width: inherit;
                height: 100%;
                object-fit: cover;
            }
        }

        .illust3 {
            width: auto;
            height: 230px;
        }

        .text {
            position: relative;
            margin-top: 0.75rem;
            z-index: 1;

            h3 {
                position: relative;
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
            }

            p {
                position: relative;
                text-align: center;
                color: $gray;
                margin-top: 1rem;
            }

            .btn {
                margin-top: 2.5rem;
            }
        }
    }
}

.success-modal {
    position: relative;

    svg {
        display: block;
        margin: auto;
    }
    img {
        display: block;
        margin: auto;
    }

    h3 {
        position: relative;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        margin-top: 2rem;
    }

    p {
        position: relative;
        text-align: center;
        color: $gray;
        margin-top: 1rem;
        line-height: 24px;
    }

    .btn {
        margin-top: 2.5rem;
    }
}

.bankaccess-modal {
    .modal-content {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }

    .image {
        text-align: center;

        svg {
            @media #{ $xsmalldown } {
                width: 100%;
                height: unset;
            }
        }
    }

    h3 {
        font-weight: 600;
    }

    .check-list {
        position: relative;
        background-color: #f5fbff;
        border-radius: 8px;
        padding: 1.5rem;
        margin-top: 1rem;

        @media #{ $xsmalldown } {
            padding: 1rem;
        }

        li {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @media #{ $xsmalldown } {
                align-items: unset;
            }
        }

        svg {
            margin: unset;
        }

        p {
            margin-top: 0;
            margin-left: 0.875rem;
            color: $black;
            flex: 1;
            text-align: unset;
        }
    }

    .btn-group {
        display: flex;

        @media #{ $xsmalldown } {
            flex-direction: column;
        }

        .btn {
            &:first-child {
                margin-right: 1.5rem;
            }

            @media #{ $xsmalldown } {
                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .acknwmnt {
        position: relative;
        max-width: 460px;
        width: 100%;
        margin: auto;
        margin-top: 1.5rem;
        font-size: 0.75rem;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: $gray;

        a {
            font-size: inherit;
            margin: 0;
            color: inherit;

            &:hover {
                color: $secondary;
            }
        }
    }
}

.loanAccessModal {
    .modal-content {
        padding-top: 3.5rem;
    }
}

.welcome-modal {
    @media #{ $largeup } {
        max-width: 757px !important;

        .modal-body {
            max-width: 685px;
        }
    }

    @media #{ $smalldown } {
        max-height: 90vh;
        overflow-y: scroll;
    }

    .modal-content {
        padding: 0;

        @media #{ $smallup } {
            display: flex;
        }

        .side {
            position: relative;
            background-color: $primary-light-1;
            padding-top: 2rem;
            border-radius: 10px;
            display: none;

            @media #{ $smallup } {
                display: block;
                width: 250px;
            }

            @media #{ $largeup } {
                width: 300px;
            }

            &--text {
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                @media #{ $mediumup } {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                .logo {
                    background-color: $primary;
                    width: 32px;
                    height: 32px;
                    border-radius: 4px;
                }

                h3 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29px;
                    text-align: left;
                    margin-top: 2rem;
                }
            }

            &--image {
                position: relative;
                z-index: 1;

                div[role="button"] {
                    position: absolute;
                    z-index: -1;
                    transform: translateX(-50%);
                    left: 50%;
                    top: -30%;
                }

                img {
                    width: 100%;
                    margin-top: 0.5rem;
                }
            }
        }

        .text {
            padding: 3.5rem 1.5rem 2rem;
            flex: 1;

            @media #{ $mediumup } {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            h3 {
                font-size: 1rem;
                font-weight: 600;
                line-height: 24px;
                color: $black;
                margin-bottom: 1rem;
            }

            p {
                position: relative;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 21px;
                margin-bottom: 1.5rem;
                color: $gray;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .signature {
                p {
                    margin-bottom: 0;

                    &:last-child {
                        font-weight: 500;
                        margin-bottom: 0.25rem;
                    }

                    &:first-child {
                        font-family: "Monsieur La Doulaise", cursive;
                        font-size: 1.5rem;
                        font-weight: 400;
                        line-height: 37px;
                    }
                }
            }
        }
    }
}

.upload-modal {
    .uploading-text {
        font-weight: 500;
        line-height: 19px;

        span {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 17px;
            text-decoration: none;
            color: $gray;
        }
    }

    .progress {
        min-width: 192px;
        margin-right: auto;
        margin-left: auto;

        & > div {
            background: #ffffff;
        }
    }

    .uploaded-text {
        display: inline-flex;
        align-items: center;

        span {
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
            text-decoration: none;
            color: $black;
        }
    }
}

.beneficiariesFAQs-modal {
    position: relative;

    .FAQs__body {
        position: relative;

        .accordion {
            width: 100%;
            margin: auto;

            &__item {
                position: relative;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid rgba(92, 99, 104, 0.2);

                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            &__heading,
            &__panel {
                width: 85%;
            }

            &__button {
                font-size: 1rem;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.02em;
                cursor: pointer;

                @media #{$mediumup} {
                    padding-right: 2.5rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    background: url(../../assets/svgs/plus-gray.svg) no-repeat
                        center;
                    background-size: 100%;
                    font-size: 0.75rem;
                    color: #0e0842;
                    border-radius: 4px;
                    cursor: pointer;

                    &:hover {
                        background: url(../../assets/svgs/plus-wine.svg)
                            no-repeat center;
                    }
                }
            }

            &__button[aria-expanded="true"]::after {
                background: url(../../assets/svgs/minus-white.svg) no-repeat
                    center;
            }

            &__panel {
                margin-top: 1.5rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $gray;

                ul {
                    list-style: disc;
                    padding-left: 1.25rem;
                    margin-bottom: 1.5rem;

                    li {
                        margin-bottom: 0.5rem;
                    }
                }

                a {
                    color: $secondary;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                        text-underline-position: below;
                    }
                }
            }
        }

        .extralinnk {
            position: relative;
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            color: $gray;
        }
    }
}

//Request loan modal
.requestloan-modal,
.withdrawal-modal,
.selfie-modal {
    .btn-group {
        display: flex;

        @media #{ $smalldown } {
            flex-direction: column;
        }

        .btn {
            &:first-child {
                margin-right: 1.5rem;
            }

            @media #{ $smalldown } {
                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}

.selfie-modal {
    .camera-box {
        position: relative;

        &__container {
            position: relative;
            width: 230px;
            height: 230px;
            background-color: $off-white;
            margin-left: auto;
            margin-right: auto;
            border-radius: 100%;

            .__selfie {
                position: relative;
                width: inherit;
                height: inherit;
                overflow: hidden;
                border-radius: 100%;
            }

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                text-align: center;
                color: $gray;
                font-size: 0.75rem;
            }

            .selfie-camera {
                position: absolute;
                bottom: 0;
                right: 1.5rem;
                z-index: 1;
            }
        }
    }
}

//choose benefit notice modal
.benefitnotice {
    &__modal {
        .modal-content,
        .modal-container-top {
            padding: 0;
            border: none;
        }
    }
    width: 100%;
    padding: 30px;

    @media #{ $mediumdown } {
        padding: 20px;
    }
    img {
        width: 100%;
        margin-bottom: 30px;

        @media #{ $mediumdown } {
            margin-bottom: 20px;
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 6px;

        @media #{ $mediumdown } {
            font-size: 14px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
        line-height: 1.5;
        color: #5c6368;

        @media #{ $mediumdown } {
            font-size: 12px;
        }
    }

    &__footer {
        background: #fff3f3;
        border-top: 1px solid #f5f5f5;
        height: 116px;
        border-radius: 0px 0px 16px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            width: 222px;

            @media #{ $mediumdown } {
                height: 44px;
                width: 89px;
            }
        }
    }
}

.pause--modal {
    &__header {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }
    &__body {
        > h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24.2px;
            text-align: center;
            color: #000b14;
            margin-bottom: 16px;
            @media #{ $mediumdown } {
                font-size: 16px;
            }
        }
        > p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #5c6368;
            @media #{ $mediumdown } {
                font-size: 12px;
            }
        }
        .__button {
            width: 100%;
        }
    }
}
