.billings__container {
    margin-top: 15px;

    &--cards {
        display: flex;
        gap: 36px;
        padding-bottom: 30px;
        @media #{ $largedown } {
            flex-direction: column-reverse;
        }
    }
    .mobile--title {
        display: none;
        font-size: 1rem;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
        margin-bottom: 20px;

        @media #{ $mediumdown } {
            display: block;
        }
    }
    &--card {
        width: 50%;
        height: 202px;

        @media #{ $slaptopdown } {
            height: 222px;
        }
        @media #{ $largedown } {
            height: 212px;
        }
        @media #{ $largedown } {
            height: fit-content;
            width: 100%;
        }
    }
    .payment--cards {
        background-color: $primary-light-1;
        border-radius: 12px;
        border: 1px solid $primary-light-1;
        height: inherit;
        .payments {
            padding: 2.5rem;
            background-color: black;
            background: url("../../assets/svgs/pinkLines.svg") no-repeat;
            border-radius: 12px;
            background-position: right;
            height: inherit;
            @media #{ $largedown } {
                padding: 1.5rem;
            }

            h3 {
                color: $black;
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 21.78px;
                text-align: left;
                @media #{ $smalldown } {
                    font-size: 1rem;
                }
            }

            p {
                color: $gray;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.004em;
                text-align: left;
                @media #{ $smalldown } {
                    font-size: 0.75rem;
                }
            }

            .__top {
                flex: unset;
            }

            .__bottom {
                padding-top: 2.8rem;
            }
        }
    }
    .billings--card {
        background-color: $secondary;
        border: 1px solid rgba(211, 211, 211, 0.6);
        height: inherit;
        border-radius: 12px;
        .flance-credit {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        .strip--container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
        .__strip {
            position: relative;
            width: fit-content;
            display: flex;
            justify-content: flex-end;
            background: #4c0016;
            height: fit-content;
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            text-transform: uppercase;
            border-radius: 100px 0px 0px 100px;
            text-transform: none;
            gap: 8px;
            P {
                color: #fff;
                font-size: 14px;
                @media #{ $smalldown } {
                    font-size: 12px;
                }
            }
            .copyBtn {
                width: 14px;
                height: 16px;

                &:hover {
                    cursor: pointer;
                }

                svg {
                    width: inherit;
                    height: inherit;
                    color: $primary;

                    &:hover {
                        color: darken($color: $primary, $amount: 5px);
                    }
                }
            }
        }
        .balance {
            padding-top: 12px;
            padding-left: 30px;
            @media #{ $smalldown } {
                padding-left: 20px;
            }
            > p {
                font-size: 0.75rem;
                font-weight: 600;
                line-height: 14.52px;
                text-align: left;
                color: #f5f5f5;
                @media #{ $smalldown } {
                    font-size: 0.6rem;
                }
            }
            > h3 {
                font-size: 28px;
                font-weight: 600;
                line-height: 33.89px;
                text-align: left;
                color: #f5f5f5;
                @media #{ $smalldown } {
                    font-size: 1rem;
                }
            }
        }
        .__button {
            @media #{ $mediumdown } {
                margin-top: 10px;
            }
            > button {
                height: 61px;
                background-color: #30030d;
                width: 100%;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                color: #ff8788;
                font-size: 14px;
                font-weight: 600;
                line-height: 16.94px;
                letter-spacing: 0.02em;
                text-align: center;
                text-transform: uppercase;
                &:hover,
                &:focus {
                    background-color: darken(#30030d, 3%);
                }
            }
        }
    }
    .credit--card {
        height: inherit;
        display: flex;

        border-radius: 12px;
        padding-left: 30px;
        padding-top: 30px;
        // padding-right: 11px;

        padding-bottom: 10px;
        @media #{ $largedown } {
            padding: 16px;
        }
        &--text {
            color: #fff;
            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 29.05px;
                text-align: left;

                padding-bottom: 12px;
                @media #{ $smalldown } {
                    font-size: 1rem;
                    padding-bottom: 4px;
                }
            }
            p {
                width: 85%;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                @media #{ $largedown } {
                    width: 100%;
                }
                @media #{ $smalldown } {
                    font-size: 0.75rem;
                }
            }
            .__btn {
                padding-top: 30px;
                @media #{ $smalldown } {
                    padding-top: 16px;
                }
            }
        }
        &--img {
            // width: 160px;
            // height: 134px;
            @media #{ $largedown } {
                display: none;
            }
            > img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .history-header {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 21.78px;
        text-align: left;
        color: $black;
    }
    .subscription {
        height: inherit;
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        &__header {
            border-bottom: 1px solid #f5f5f5;
            @media #{ $slaptopdown } {
                display: none;
            }
            > h3 {
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: 0.004em;
                text-align: left;
                padding: 16px 30px;
                color: $black;
            }
        }
        &__body {
            padding: 20px 30px;
            @media #{ $slaptopdown } {
                padding: 15px;
            }
            &--details {
                display: flex;
                justify-content: space-between;
                @media #{ $smalldown } {
                    justify-content: flex-start;
                    gap: 30px;
                }
            }
            &--detail {
                > p {
                    color: $gray;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14.52px;
                    text-align: left;
                    text-transform: uppercase;
                }
                > h4 {
                    color: $black;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 19.36px;
                    text-align: left;
                    margin-top: 6px;
                    @media #{ $largedown } {
                        font-size: 12px;
                    }
                }
            }
            &--btn {
                width: 100%;
                display: flex;
                gap: 40px;
                padding-top: 30px;
                @media #{ $slaptopdown } {
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}
.payment__container {
    margin-top: 16px;
    margin-bottom: 30px;
    .payment--methods {
        display: flex;
        gap: 36px;
        @media #{ $largedown } {
            flex-direction: column;
        }
    }
    .payment--method {
        width: 50%;
        height: 80px;
        @media #{ $largedown } {
            width: 100%;
        }
        .flance--balance {
            padding: 16px 30px;
            background-color: $primary-light-2;
            border-radius: 12px;
            border: 1px solid $primary;
            display: flex;
            justify-content: space-between;
            height: inherit;
            align-items: center;
            @media #{ $smalldown } {
                padding: 16px 15px;
            }
            .__side {
                display: flex;
                align-items: center;
                gap: 10px;
                > p {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: left;
                    color: $black;
                    span {
                        font-size: 0.875rem;
                        font-weight: 600;
                    }
                    @media #{ $smalldown } {
                        font-size: 0.6rem;
                    }
                }
            }
        }
        .--default {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
            > p {
                color: $secondary;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                cursor: pointer;
                transition: all 0.5s;
                text-align: right;
                @media #{ $smalldown } {
                    font-size: 0.6rem;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                font-size: 12px;
                font-weight: 600;
                line-height: 14.52px;
                text-align: left;
                color: #236f00;
                background: #eafff4;
                padding: 4px 8px;
                border-radius: 4px;
                @media #{ $smalldown } {
                    font-size: 0.6rem;
                }
            }
            .trash {
                cursor: pointer;
            }
            .default {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
        .saved--card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid rgba(211, 211, 211, 0.6);
            border-radius: 12px;
            height: inherit;
            padding: 16px 30px;
            .__side {
                display: flex;
                gap: 10px;
                .card--info {
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16.94px;
                        text-align: left;
                        color: $black;
                        @media #{ $smalldown } {
                            font-size: 0.6rem;
                        }
                    }
                    span {
                        color: $gray;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        @media #{ $smalldown } {
                            font-size: 0.6rem;
                        }
                    }
                }
            }
        }
    }
}
.subs__container {
    margin-bottom: 30px;

    &--body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        padding: 16px 30px;
        @media #{ $mediumdown } {
            padding: 20px;
        }
        > .__side {
            display: flex;
            width: 70%;
            @media #{ $mediumdown } {
                flex-direction: column;
            }
        }

        .name {
            width: 60%;
            > p {
                color: $f-blue;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: left;
                margin-top: 6px;
            }
        }
        .amount {
            width: 40%;
            font-weight: 600;
            line-height: 14.52px;
            text-align: left;
            @media #{ $mediumdown } {
                margin-top: 12px;
            }
            > p {
                font-size: 12px;

                color: $gray;
            }
            > h4 {
                font-size: 16px;
                color: $black;
                margin-top: 6px;
                > span {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12.1px;
                    text-align: left;
                }
            }
        }
        .__btn {
            width: 30%;
            display: flex;
            justify-content: flex-end;
        }
    }
}
.warning {
    .warningText {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        color: #e0063c;
        background: #dbdbdb;
        padding: 10px 20px;
        border-radius: 8px;
    }
    .gym-banner__container {
        width: 100%;
        background-color: #f8fcff;
        border-radius: 8px;
        padding: 12px 20px;
        margin-top: 20px;
    }
    .__btn {
        display: flex;
        gap: 48px;
        margin-top: 40px;
        @media #{ $mediumdown } {
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
        }
    }
}
