//Base
    //author: @Anisat Yunusa

//Reset

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    outline: none;
}

html {
    color: $black;
}

body {
    color: $black;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: normal;
    scroll-behavior: smooth;
}

a {
    outline: none;
    text-decoration: none;
}

ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

svg {
    vertical-align: middle;
    fill: inherit;
}

button {
    outline: none;
    border: 0;
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    opacity: 0;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    opacity: 0;
}

.tab {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}


//Typography
@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url(../../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url(../../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url(../../assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url(../../assets/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url(../../assets/fonts/Inter-ExtraBold.ttf) format('truetype');
}


.avatar {
  &--round {
    border-radius: 50% !important;
  }
  &--round-squared {
    border-radius: 5px !important;
    margin-right: 15px;
  }
}


