.cart__container {
    transition: all 0.5s;
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
    }
    .cart__container--header {
        height: 10vh;
        border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        padding: 0 34px;
        cursor: pointer;
        @media #{ $smalldown } {
            padding: 0 20px;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .close__btn {
            position: fixed;
            transition: 0.5s;
            right: 745px;

            svg {
                padding: 10px;
                background-color: #ffffff;
                border-radius: $radius-c;
                &:hover {
                    color: #ff8788;
                }
                @media #{ $smalldown } {
                    background: #ff8788;
                }
            }
            @media #{ $largedown } {
                right: 520px;
            }
            @media #{ $smalldown } {
                position: static;
            }
        }
        .__details {
            display: flex;
            align-items: center;
            gap: 10px;
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 1.125rem;
                line-height: 22px;
                color: $black;
            }
        }
    }

    .cart-items {
        margin-top: 24px;
        padding: 0 32px;
        height: 76.5vh;
        @media #{ $smalldown } {
            padding: 0 20px;
        }
        .cart-item {
            padding: 16px 0;
            border-bottom: 1px solid #f5f5f5;
            &:last-child {
                border-bottom: none;
            }
            .company__details {
                display: flex;
                gap: 16px;
                align-items: flex-start;
                img {
                    width: 40px;
                    height: 40px;
                }

                &--plan {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    @media #{ $xs-md-down } {
                        align-items: flex-start;
                        justify-content: space-between;
                        flex-direction: column;
                    }
                    .__name {
                        h2 {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 0.875rem;
                            // line-height: 24px;
                            letter-spacing: 0.004em;
                            color: $black;
                            text-transform: uppercase;
                        }
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 0.75rem;
                            line-height: 24px;
                            letter-spacing: 0.004em;
                            color: $gray;
                        }
                    }
                    .price {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 0.875rem;
                        line-height: 24px;
                        letter-spacing: 0.004em;
                        color: $black;
                        span {
                            font-weight: 500;
                            font-size: 0.75rem;
                            color: $gray;
                        }
                    }
                }
                .remove {
                    margin-top: 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                    cursor: pointer;
                    text-decoration-line: underline;
                    color: #3d0814;
                    // background: none;
                }
            }
        }
    }

    .benefit__details {
        overflow: scroll;
        height: 80.5vh;
        p {
            color: rgba(0, 11, 20, 0.8);
            font-size: 0.875rem;
            line-height: 140%;
        }
        h2 {
            color: #005397;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: uppercase;
        }
        &--detail {
            margin-bottom: 40px;
            > p {
                color: $gray;
                letter-spacing: 0.0035rem;
            }
        }
        &--overview {
            border-bottom: 1px solid #f5f5f5;
            > h2 {
                margin-bottom: 24px;
            }
            .overview--container {
                display: flex;
                gap: 103px;
                margin-bottom: 16px;
            }
            > .savings {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #f5f5f5;
                padding-bottom: 1rem;
                &:last-child {
                    border: none;
                    padding-bottom: 0rem;
                }
            }
        }
        &--general {
            margin-top: 32px;
            .outPatient--limit {
                margin-top: 24px;
                padding-bottom: 16px;
                display: flex;
                gap: 50px;
                border-bottom: 1px solid #f5f5f5;
                @media #{ $largedown } {
                    flex-direction: column;
                    gap: 1rem;
                }
            }
            .inPatient--limit {
                margin-top: 16px;
                padding-bottom: 16px;
                display: flex;
                gap: 50px;
                border-bottom: 1px solid #f5f5f5;
                @media #{ $largedown } {
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }
        &--services {
            margin-top: 32px;
            h2 {
                padding-top: 2rem;
                &:first-child {
                    padding-top: 0;
                }
            }
            .services--container {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                padding-bottom: 16px;
                border-bottom: 1px solid #f5f5f5;
                @media #{ $largedown } {
                    flex-direction: column;
                    gap: 1rem;
                }
                span {
                    color: #005397;
                }
            }
        }
        &--exclusions {
            margin-top: 32px;

            h2 {
                margin-bottom: 8px;
            }
            ul {
                list-style-type: disc !important;
                padding: 20px;
                // list-style: decimal;

                li {
                    font-weight: 400;
                    font-size: 14px;
                    // list-style-type: circle !important;
                    color: $black;
                    padding-bottom: 8px;
                }
            }
        }
        &--notes {
            margin: 32px 0;
            .notes__container {
                background-color: #f8fcff;
                padding: 1rem;
                border-radius: 16px;
                &--header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    gap: 6px;
                    color: #000b14;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
                ul {
                    list-style-type: disc !important;
                    padding: 20px;

                    li {
                        font-weight: 400;
                        font-size: 14px;
                        // list-style-type: circle !important;
                        color: $black;
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }

    .cart__container--footer {
        height: 10vh;
        position: absolute;
        z-index: 1000;
        bottom: 0px;
        border-top: 1px solid #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: #fff;
        padding: 0 33px;

        @media #{ $smalldown } {
            justify-content: center;
            align-items: center;
            position: fixed;
            padding: 0 20px;
        }

        .btn {
            height: 40px;
            font-size: 0.875rem;

            @media #{ $smalldown } {
                width: 100%;
            }
        }
    }

    .collapsed {
        width: 720px;
        @media #{ $largedown } {
            width: 500px;
        }
        @media #{ $smalldown } {
            width: 100%;
        }
    }
}
.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background: rgba(0, 11, 20, 0.85);
}
