//Explore Page
//author: @Anisat Yunusa

.explore {
    position: relative;

    .detail--descrip {
        border: 0;
        margin-top: 16px;
    }

    &--plans {
        position: relative;
        // margin-top: 2.5rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2.25rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{ $largeup } {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
.benefit__selection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 12px 12px 0px 0px;

    margin-top: 20px;
    .btn--selction {
        width: 50%;
        cursor: pointer;
        > p {
            text-transform: uppercase;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            line-height: 19.36px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            color: #3d0814;
            @media #{ $smalldown } {
                font-size: 0.875rem;
            }
        }
    }
    .active {
        background-color: #005397;
        border-radius: 12px 12px 0px 0px;
        p {
            color: #ffffff;
        }
    }
}
.__filter--options {
    @media #{ $smallup } {
        position: relative;
    }

    .options {
        display: flex;
        gap: 10px;
        align-items: center;
        background: #f5f5f5;
        border-radius: 50px;
        border: 1px solid #e4e7ec;
        padding: 10.5px 12px;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 14.52px;
        text-align: left;
        cursor: pointer;

        // transition: all 500ms;

        &:hover {
            background: darken($color: #f5f5f5, $amount: 5);
        }
        > svg {
            opacity: 0.2;
        }
    }
    .filter__dropdown {
        .state_select {
            max-height: 500px !important;
            height: 462px !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .action--btn {
                padding: 0.5rem 1rem;
            }
            @media #{ $mediumdown } {
                position: absolute;
                right: 0 !important;
                left: auto !important;
            }
        }
        .__dropdown {
            z-index: 1;
            position: absolute;
            right: 0;
            padding-top: 0.5rem;
            min-width: 350px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
            box-sizing: border-box;
            max-height: 350px;
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 5px;
                height: 10px;
                opacity: 1;
            }

            @media #{ $mediumdown } {
                left: 0 !important;
            }
            @media #{ $smalldown } {
                left: -15px !important;
            }

            .state__lga {
                height: 90%;
                overflow: scroll;
            }

            .state {
                border: 1px solid rgba(92, 99, 104, 0.3);
                margin: 20px 20px 12px 20px;
                border-radius: 8px;
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 10px;
                    opacity: 1;
                }
            }
            .open__state {
                height: 280px !important;
                overflow: scroll;
            }
            .lga {
                height: 280px !important;
                overflow: scroll;
            }
            .selectLga_disable {
                background: #e9e9e9;
                padding: 10px;
                cursor: not-allowed;
                display: flex;
                justify-content: space-between;
                align-items: center;
                > svg {
                    opacity: 0.3;
                }
                > p {
                    font-size: 0.75rem;
                    font-weight: 600;
                    line-height: 14.52px;

                    color: rgba(0, 11, 20, 0.3);
                }
            }
            .enable {
                cursor: pointer;
                background-color: transparent;
                border: 1px solid rgba(92, 99, 104, 0.3);
                color: $black;
                > p {
                    color: $black;
                }
            }

            li {
                width: 100%;
                border-radius: 0;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.004em;
                padding: 0.5rem 1rem;
                color: $gray;

                display: flex;
                align-items: center;
                gap: 10px;
                > input {
                    cursor: pointer;
                }
            }
            .action--btn {
                display: flex;
                justify-content: space-between;
                padding: 1.5rem 1rem;
                padding-bottom: 20px;
                .btn {
                    width: 45%;
                }
                .reset {
                    border: 1px solid rgba(43, 43, 43, 0.8);
                }
            }
        }
    }
}

.ThreePlanCard {
    position: relative;
    padding: 2.5rem 1.5rem;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    border-radius: 10px;

    // transition: all ease-out .5s;
    &:hover {
        background: $primary-light-1;
        border: none;

        .__button {
            background: $primary;
        }
    }

    .__name {
        position: relative;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 24px;
    }

    .__value {
        position: relative;
        font-size: 2rem;
        font-weight: 600;
        line-height: 39px;
        margin-top: 1rem;

        .__period {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 17px;
            color: $gray;
        }
    }

    .__button {
        position: relative;
        height: 50px;
        margin-top: 2rem;
        // border-radius: 4px;
    }

    &__benefits {
        position: relative;
        margin-top: 2.5rem;

        p {
            position: relative;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 21px;
            text-transform: uppercase;
            color: $secondary;
        }

        ul li {
            margin-top: 1rem;
            text-transform: capitalize;

            svg {
                color: $secondary;
                margin-right: 0.875rem;
            }
        }
    }
}

.gym--header {
    position: sticky;
    height: fit-content;
    z-index: 1000;

    top: 80px;
    background-color: #fff;
    padding-bottom: 2.5rem;
    @media #{ $mediumdown } {
        width: 100%;
    }
}

.gym__filter {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1250px) {
        flex-direction: column;
        gap: 20px;
    }
    .name--location__search {
        display: flex;
        gap: 12px;
        @media #{ $mediumdown } {
            flex-direction: column;
        }
        .name--search {
            display: flex;

            .search__container {
                display: flex;
                // gap: 0.5rem;
                border: 1px solid #f5f5f5;
                width: 337px;
                height: 40px;
                padding: 5px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                background: #f5f5f5;
                transition: background 0.3s;
                &:hover {
                    background: darken($color: #f5f5f5, $amount: 9);
                }
                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15%;
                }
                > input {
                    width: 85%;
                    height: 100%;
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 16.94px;
                    outline: transparent;
                    border: transparent;
                    color: rgba(102, 112, 133, 0.8);
                    background-color: transparent;
                }
            }
        }
        .location--search {
            display: flex;
            gap: 0.5rem;
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 16.94px;
            letter-spacing: 0.02em;
            align-items: center;
            cursor: pointer;
            color: #3d0814;
            // > span {
            //     svg {
            //         color: #c4b7bd;
            //         &:hover {
            //             color: #ff8788;
            //         }
            //     }
            // }
            > span {
                color: #c4b7bd;
            }
            &:hover {
                color: #ff8788;
                > span {
                    color: #ff8788;
                }
            }
        }
    }
    .__filter {
        position: relative;
        &--items {
            display: flex;
            gap: 20px;

            @media #{ $smalldown } {
                overflow-x: auto;
            }
        }
    }
}
.selectedOptions {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 20px;
    .show__filter {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        background-color: #f5f5f5;
        padding: 0px 12px;
        height: 36px;
        border-radius: 50px;
        border: 1px solid #e4e7ec;
        p {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 14.52px;
            color: $black;
        }
        span {
            border: 1px solid #005397;
            height: 20px;
            width: 20px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 0.6rem;
            font-weight: 400;
            line-height: 12.1px;
            text-align: left;
        }
    }
    .options {
        > ul {
            display: flex;
            gap: 20px;
            li {
                border: 1px solid #e4e7ec;
                padding: 0px 12px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                color: $black;
                height: 36px;

                span {
                    margin-left: 6px;
                    cursor: pointer;
                }
            }
        }
    }
    .clear {
        border: 1px solid #e4e7ec;
        padding: 0px 12px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #ffffff;
        height: 36px;
        background-color: #005397;
        cursor: pointer;
    }
}

.reset__filter {
    max-width: 450px;
    margin: 0 auto;
    margin-top: 20px;

    &--img {
        width: 163px;
        height: 163px;
        margin: 0 auto;
        > img {
            width: 100%;
            height: 100%;
        }
    }
    > h2 {
        color: $black;
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 43.57px;
        text-align: center;
        margin-top: 10px;
    }
    > p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 19.36px;
        text-align: center;
        color: #656d76;
        margin-bottom: 20px;
    }
}
