.choosepass {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 14px;

    &__card {
        width: 100%;
        max-width: 263px;
        border-radius: 12px;
        border: 1px solid rgba(211, 211, 211, 0.6);
        padding: 24px;

        @media screen and (max-width: 820px) {
            padding: 20px;
        }

        &-content {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(211, 211, 211, 0.6);

            @media screen and (max-width: 820px) {
                padding-bottom: 10px;
                margin-bottom: 16px;
            }
        }

        &-title {
            margin-top: 20px;
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 12px;

            &.basic-pass {
                color: #005397;
            }

            &.standard-pass {
                color: $primary;
            }

            &.premium-pass {
                color: $secondary;
            }

            &.lite-pass {
                color: #2d8ef9;
            }

            @media screen and (max-width: 820px) {
                margin-top: 12px;
                font-size: 20px;
                margin-bottom: 6px;
            }
        }

        &-description {
            font-size: 12px;
            line-height: 1.5;
            color: #5c6368;
            font-weight: 500;
        }

        &-price {
            font-size: 32px;
            font-weight: 600;
            color: #000b14;

            span {
                font-size: 16px;
                font-weight: 500;

                @media screen and (max-width: 820px) {
                    font-size: 12px;
                }
            }

            @media screen and (max-width: 820px) {
                font-size: 24px;
            }
        }

        &-whatyouget {
            h4 {
                font-size: 16px;
                font-weight: 500;
                color: #000b14;
                margin-bottom: 16px;
                @media #{ $mediumdown } {
                    font-size: 12px;
                }
            }

            li {
                display: flex;
                align-items: center;
                gap: 6px;
                color: #5c6368;
                font-size: 14px;
                margin-bottom: 18px;

                @media #{ $mediumdown } {
                    font-size: 12px;
                }
            }
        }

        &-actions {
            .btn {
                width: 100%;
                height: 44px;
                font-size: 14px;

                &:first-child {
                    margin-bottom: 16px;
                }

                @media #{ $mediumdown } {
                    height: 40px;
                }
            }
        }
    }

    &__toggle {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: scroll;

        &-btn {
            padding: 8px 31px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 100px;
            border: 1px solid #e4e7ec;
            background-color: #f5f5f5;
            transition: background-color 0.3s ease, color 0.3s ease;
            white-space: nowrap;

            &.active,
            &:hover {
                background-color: #005397;
                color: #fff;
            }

            @media screen and (max-width: 820px) {
                padding: 8px 12px;
                font-size: 12px;
            }
        }
    }

    &__benefits {
        display: flex;
        align-items: center;
        margin-top: 10px;

        &-text {
            font-size: 14px;
            font-weight: 500;
            color: #5c6368;
            margin-left: 6px;

            @media screen and (max-width: 820px) {
                font-size: 0.75rem;
            }

            &.popular {
                color: #fff;
            }
        }
    }

    &__purchase {
        &-rate {
            color: #005397;
            font-size: 14px;
            span {
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

.most-popular {
    color: #fff !important;
    background: $secondary;

    .choosepass__card-description {
        color: #fff;
    }

    .choosepass__card-price {
        color: #fff;
    }

    .choosepass__card-whatyouget {
        h4 {
            color: #fff;
        }

        li {
            color: #fff;
        }
    }

    &__tag {
        background: #fff3f3;
        color: $secondary;
        padding: 8px 12px;
        border-radius: 100px;
        font-size: 12px;
    }
}

.flancepass {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    flex-wrap: wrap;

    @media screen and (max-width: 635px) {
        display: block;
    }

    &__card {
        border: 1px solid #f5f5f5;
        border-radius: 12px;
        flex: 1;
        position: relative;
        @media screen and (max-width: 635px) {
            margin-bottom: 20px;
        }

        &-code {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 24px;
            border-bottom: 1px solid #f5f5f5;
            height: 70px;

            @media screen and (max-width: 820px) {
                padding: 16px 20px;
                height: 62px;
            }

            svg {
                width: 48px;
                height: 48px;

                @media screen and (max-width: 820px) {
                    width: 40px;
                    height: 40px;
                }
            }
            p {
                color: #000b14;
                font-weight: 500;
            }

            &-bottom {
                display: flex;
                align-items: center;
                gap: 30px;
                padding: 24px;
                height: 112px;

                @media screen and (max-width: 820px) {
                    gap: 20px;
                }

                p {
                    text-transform: uppercase;
                    color: #5c6368;
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 12px;

                    @media screen and (max-width: 820px) {
                        font-size: 0.875rem;
                    }
                }

                h4 {
                    font-size: 24px;
                    color: #000000;
                    font-weight: 700;

                    @media screen and (max-width: 820px) {
                        font-size: 20px;
                    }
                }
            }
        }

        &-details {
            flex: 2;

            &--top {
                padding: 16px 24px;
                border-bottom: 1px solid #f5f5f5;
                height: 70px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h5 {
                    font-size: 1rem;
                    font-weight: 500;
                    color: #000b14;

                    @media screen and (max-width: 820px) {
                        font-size: 0.875rem;
                    }
                }

                p {
                    font-size: 12px;
                    color: #e0063c;
                    margin-top: 4px;

                    @media screen and (max-width: 820px) {
                        font-size: 0.75rem;
                    }
                }
            }
            &--bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 24px;
                min-height: 112px;
                gap: 20px;

                .deets {
                    h4 {
                        font-size: 12px;
                        font-weight: 600;
                        color: #5c6368;
                        margin-bottom: 12px;
                        text-transform: uppercase;
                    }

                    p {
                        color: #000b14;
                    }
                    span {
                        padding: 4px 8px;
                        border-radius: 4px;
                        font-size: 0.7rem;
                        font-weight: 500;
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                    .active {
                        background: #ebf7ee;
                        color: #236f00;
                    }
                    .expired {
                        color: #6f0000;
                        background: #fff3f4;
                    }
                    .inactive {
                        background: #f5f5f5;
                        color: #5f6062;
                    }
                }
            }
        }

        &-complete {
            padding: 24px;

            h4 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            p {
                font-size: 12px;
                color: #5c6368;
            }

            &-icon {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-top: 24px;

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    color: #000b14;
                    font-weight: 600;
                }
            }

            &-illust {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    &__offer {
        &-header {
            background: #fff3f3;
            border-radius: 12px 12px 0px 0px;
            padding: 16px 24px;

            h3 {
                color: #000b14;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
            }

            @media screen and (max-width: 820px) {
                padding: 16px 20px;
            }
        }

        &-body {
            padding: 16px 24px;
            border: 1px solid #fff3f3;
            border-radius: 0px 0px 12px 12px;

            &-title {
                margin-bottom: 12px;
                h4 {
                    color: #000b14;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    margin-bottom: 6px;
                }

                p {
                    color: #000b14;
                    font-weight: 600;
                    font-size: 28px;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000b14;
                        text-transform: lowercase;
                    }
                }
            }

            &-list {
                p {
                    color: #000b14;
                    font-size: 14px;
                    margin-bottom: 12px;
                    span {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
