//Card
//author: @Anisat Yunusa

.card {
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-white {
    @extend .card;
    background: #ffffff;
    border: 1px solid $off-white;
}

.card-wine {
    @extend .card;
    color: #ffffff;
    background: $secondary;
}

.__value--yield {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    margin-left: 0.875rem;
}

//PLAN CARD
.plan-card {
    position: relative;
    padding: 1.5rem;
    background: #ffffff;
    border: 1px solid $off-white;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 373px;
    display: flex;
    flex-direction: column;

    &__main {
        flex: 1;
    }

    &__button {
        justify-content: flex-end;
    }

    &__top {
        position: relative;
        margin-bottom: 1.25rem;
    }

    &__image {
        position: relative;
        width: 100%;
        height: 140px;

        img {
            position: relative;
            border-radius: 10px;
            width: 100%;
            object-fit: cover;
            height: 100%;
            max-height: 100%;
        }
    }

    &__priceInfo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1rem;
        z-index: 2;
        background: linear-gradient(
            102.81deg,
            rgba(0, 0, 0, 0.7) 36.93%,
            rgba(0, 0, 0, 0) 124.27%
        );
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .__name {
            position: relative;
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
            color: $off-white;
        }

        .__value {
            position: relative;
            font-size: 1.875rem;
            font-weight: 600;
            line-height: 36px;
            color: #ffffff;
            margin-top: 0.5rem;

            .__period {
                font-size: 0.87rem;
                font-weight: 500;
                line-height: 17px;
                color: rgba($color: $off-white, $alpha: 0.9);
            }
        }
    }

    &__bottom {
        position: relative;

        .__catchPhrase {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            text-transform: uppercase;
            color: $gray;
        }

        .__benefits {
            position: relative;
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.5rem;
            row-gap: 0.5rem;

            &--type {
                position: relative;
                background: $off-white;
                border-radius: 100px;
                width: fit-content;
                padding: 0.25rem 0.625rem;
                min-height: 24px;

                p {
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 15px;
                    color: $gray;
                    text-transform: capitalize;
                }
            }
        }
    }

    &__button {
        .btn--secondary {
            &:hover,
            &:focus {
                background-color: $primary;
            }
        }
    }
}

//Benefit card
.card-benefit {
    @extend .card-white;
    height: 190px;
    display: flex;
    flex-direction: column;

    @media #{ $mediumdown } {
        height: unset;
        min-height: 190px;
    }

    @media #{ $smalldown } {
        height: unset;
    }

    &-top {
        flex: 1;
    }

    &__title {
        position: relative;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__status {
        display: flex;
        justify-content: flex-end;
        padding-top: 1.5rem;
        padding-right: 1.5rem;

        .__container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            height: 20px;
            padding: 0 1rem;
            box-sizing: border-box;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 15px;
            text-align: center;
        }

        .status-active {
            color: $success;
            background: #f1fef4;
        }

        .status-inactive {
            color: $gray;
            background: $off-white;
        }

        .status-expired {
            color: $off-white;
            background: $pending;
        }
    }

    &__info {
        position: relative;
        padding: 0.5rem 1.5rem 0 1.5rem;
        overflow: hidden;

        .__title {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 20px;
            color: $gray;
            text-transform: uppercase;
        }

        .__value {
            position: relative;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 20px;
            margin-top: 1rem;
            display: flex;
            align-items: center;

            &--yield {
                color: $secondary;
            }
        }

        &-img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-pattern {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    &__link {
        &--btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $f-blue;
            font-family: "Inter", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            padding: 1rem 1.5rem;
            background: $primary-light-1;
            backdrop-filter: blur(30px);
            border-radius: 0px 0px 10px 10px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                color: darken($color: $f-blue, $amount: 6);
            }

            svg {
                color: inherit;
            }
        }
    }
}

//New benefit card
.benefitcard {
    border: 1px solid #d3d3d399;
    background-color: #fff;
    border-radius: 12px;
    padding: 30px 26px;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: auto;
    min-width: 455px;
    @media screen and (max-width: 820px) {
        flex-grow: unset;
        flex-basis: unset;
        padding: 20px;
        min-width: 100%;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 12px;
    }

    &__title {
        img {
            width: 40px;
            @media screen and (max-width: 820px) {
                width: 30px;
            }
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            color: #005397;

            @media screen and (max-width: 820px) {
                font-size: 14px;
            }
        }
    }

    &__status {
        border-radius: 4px;
        padding: 4px 8px;
        height: fit-content;
        align-self: end;

        &.EXPIRED {
            background-color: #fff3f3;
            color: #e0063c;
        }

        &.ACTIVE {
            background-color: #f1fef4;
            color: #009620;
        }

        &.INACTIVE {
            background-color: #f5f5f5;
            color: #5f6062;
        }

        &.PENDING {
            background-color: #fef1dd;
            color: #543303;
        }

        &.PAUSED {
            background-color: #f5f5f5;
            color: #5f6062;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }

            @media screen and (max-width: 820px) {
                font-size: 12px;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__label {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        color: #5c6368;
        margin-bottom: 6px;
        text-transform: uppercase;
    }

    &__value {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5;
        color: #000b14;

        @media screen and (max-width: 820px) {
            font-size: 14px;
        }

        span {
            font-size: 10px;
            text-transform: lowercase;
        }
    }

    &__seedetail {
        @media screen and (max-width: 820px) {
            width: 100%;
            margin-top: 10px;
            padding-top: 12px;
            border-top: 1px solid #f5f5f5;
        }
        button {
            height: 44px;
            width: 100%;
            padding: 14px 12px;
            font-size: 14px;
        }
    }

    &__action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding-top: 12px;
        border-top: 1px solid #f5f5f5;
        gap: 30px;

        button {
            height: 44px;
            width: 100%;
            flex-grow: 1;
            flex-basis: 0;
        }
    }
}

//Balance card
.card-balance {
    @extend .card-wine;
    min-height: 190px;
    display: flex;
    flex-direction: column;

    @media #{ $mediumdown } {
        height: unset;
        min-height: 134px !important;
    }

    @media #{ $xs-md-down } {
        height: 134px !important;
    }

    .__top {
        padding: 20px 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;

        .-pattern {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 120px;
            transform: scaleX(-1);

            @media #{ $mediumdown } {
                width: 80px;
            }
        }
    }

    .-pattern {
        position: absolute;
        bottom: 59px;
        right: 0;
        width: 120px;
        transform: scaleX(-1);

        @media #{ $xs-md-down } {
            bottom: 4px;
        }
    }

    .__title {
        position: relative;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 17px;
        color: rgba($color: $off-white, $alpha: 0.8);
        text-transform: uppercase;

        @media #{ $mediumdown } {
            font-size: 12px;
        }
    }

    .__value {
        position: relative;
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 1.5;
        margin-top: 16px;

        @media #{ $mediumdown } {
            font-size: 1rem;
            margin-top: 6px;
        }
    }

    .btn {
        color: $primary;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.02em;
        background: #30030d;
        border-radius: 0px 0px 10px 10px;
        height: 61px;

        @media #{ $mediumdown } {
            height: 53px;
        }

        // transition: color .2s ease-in;
        &:hover {
            background: darken($color: #30030d, $amount: 1px);
        }
    }

    .savings__btns {
        position: relative;
        background: #30030d;
        margin-top: 1.5rem;
        padding: 8px 0;
        height: 60px;
        border-radius: 0px 0px 10px 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media #{ $xs-md-down } {
            font-size: 0.675rem;
            height: 53px;
            margin-top: 0px;
        }

        // column-gap: 2px;
        // @media #{ $mediumup } {
        //     margin-top: 1rem;
        // }

        .btn {
            font-size: 0.875rem;
            height: 100%;
            border-radius: unset;
            border-left: 1px solid rgba($color: $primary-light-1, $alpha: 0.3);
            color: $primary;

            @media #{ $xs-md-down } {
                font-size: 0.675rem;
            }

            &:hover {
                color: darken($color: $primary, $amount: 5px);
            }

            &:first-child {
                border-left: 0;
            }

            .text-btn {
                @media #{ $xs-md-down } {
                    display: none;
                }
            }

            .text-btn-alt {
                @media #{ $xs-md-up } {
                    display: none;
                }
            }
        }
    }
}

//Stats card
.stats-card {
    @extend .card-white;
    padding: 1.5rem;

    &__top {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .form-control {
            width: unset;
            min-width: 120px;
            // height: 32px;
            font-size: 0.75rem;
            padding: 0.5rem 0.75rem;
            border-radius: 6px;
        }

        select {
            background: url(../../assets/svgs/Down-arrow.svg) right 0.5rem
                center no-repeat;
        }
    }

    &__main {
        position: relative;
        margin-top: 2rem;

        .__title {
            position: relative;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 17px;
            color: rgba($color: $gray, $alpha: 0.8);
            text-transform: uppercase;
        }

        .__value {
            position: relative;
            font-weight: 600;
            font-size: 2rem;
            line-height: 39px;

            @media #{ $mediumup } {
                font-size: 1.5rem;
                line-height: 32px;
            }

            @media #{ $slaptop } {
                font-size: 2rem;
                line-height: 39px;
            }
        }
    }
}

//LINKED BANK CARD
.bankCard {
    color: rgba($color: #ffffff, $alpha: 0.8);
    border-radius: 10px;
    background: $secondary url("../../assets/svgs/bank_card_bg.svg") no-repeat;
    background-size: cover;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    @media #{ $xsmalldown } {
        padding: 1rem 1.25rem;
    }

    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex: 1;
    }

    .notDefault {
        text-decoration: underline;
        cursor: pointer;
        color: rgba(#ffffff, 0.7);
    }

    &__tag {
        position: relative;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        background-color: #30030d;
        padding: 2px 6px;
        min-height: 20px;
        color: rgba($color: #ffffff, $alpha: 1);
        align-self: flex-start;
    }

    .btn {
        height: unset;
        padding: 0;
        color: inherit;
        align-self: flex-start;
        transition: color 0.25s ease-in-out;

        &:hover {
            color: rgba($color: #ffffff, $alpha: 1);
        }
    }

    &__bottom {
        position: relative;

        h3 {
            position: relative;
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            color: rgba($color: #ffffff, $alpha: 1);
        }

        .__bottom {
            position: relative;
            margin-top: 2px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 17px;

            p {
                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }
}

//PAYMENT METHOD CARD
.paymentMtd--car {
    @media #{ $xs-md-up } {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media #{ $smallup } {
        padding: 1rem 2rem;
    }

    &__left {
        position: relative;
        display: flex;

        .cardDeets {
            position: relative;
            margin-left: 0.5rem;

            h3 {
                position: relative;
                font-size: 1rem;
                font-weight: 500;
                line-height: 19px;
            }

            .__expiry {
                position: relative;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 17px;
                color: $gray;
                margin-top: 0.5rem;
            }
        }
    }

    &__status {
        position: relative;
        padding: 2px;
        min-width: 70px;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        color: $secondary;
        background: $primary-light-2;
        border-radius: 4px;
        margin-left: 0.875rem;
        align-self: start;
    }

    &__right {
        @media #{ $xs-md-down } {
            margin-left: 2.5rem;
            margin-top: 1.25rem;
        }

        .btn {
            font-size: 14px;
            // font-weight: 400;
            line-height: 17px;
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
                color: $gray;

                &:hover,
                &:focus {
                    color: $danger;
                }
            }
        }
    }
}
