.renewal {
    &__form {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: scroll;

        @media #{ $xs-md-down } {
            padding-bottom: 100px;
        }

        .selectcard-grid {
            @media #{ $largeup } {
                grid-template-columns: repeat(3, 1fr) !important;
            }

            @media #{ $slaptop } {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .paymentMtd-grid {
            @media #{ $largeup } {
                grid-template-columns: repeat(2, 1fr) !important;
            }

            @media #{ $slaptop } {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            padding: 16px 20px;
            background-color: #f5fbff;
            border-radius: 12px;
            width: 100%;

            .benefitcard__title img {
                width: 24px;
            }
        }

        .__subtext {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $gray;
            margin-bottom: 1rem;
        }

        .__theForm {
            position: relative;
            margin-top: 0;
            margin-bottom: 30px;

            .btn-group {
                display: flex;
                @media #{ $xsmalldown } {
                    flex-direction: column;
                }

                .btn {
                    width: 100%;
                    &:first-child {
                        margin-right: 1.5rem;
                    }

                    @media #{ $xsmalldown } {
                        &:first-child {
                            margin-right: 0;
                        }
                        &:last-child {
                            margin-top: 1.5rem;
                        }
                    }
                }
            }

            .selectcard-grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 1rem;

                .card-check {
                    margin-bottom: 0;
                }

                @media #{ $mediumup } {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1.5rem;
                }
            }

            .info-banner {
                a {
                    color: $f-blue;
                }
            }
        }
    }
    &__success {
        text-align: center;
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 20px;

            @media #{ $smalldown } {
                font-size: 16px;
            }
        }

        &-info {
            margin-top: 36px;

            h5 {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 16px;

                @media #{ $smalldown } {
                    font-size: 16px;
                }
            }
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
                color: #5c6368;
            }
        }
    }
}
