//GETTING STARTED LAYOUT
//author: @Anisat Yunusa

.getting-started {
    position: relative;
    min-height: 100vh;
    padding: 80px 1.5rem 0;

    &--nav {
        .topnav-right {
            p {
                font-size: 1rem;
                font-weight: 500;
                line-height: 24px;
                color: $gray;
            }

            a {
                color: $black;
                cursor: pointer;
                &:hover {
                    color: $secondary;
                }
            }
        }
    }

    &--main {
        width: 100%;
        height: 100%;
        max-width: 1150px;
        width: 100%;
        margin: auto;
        margin-top: 3rem;
        padding-bottom: 4.5rem;
        @media #{ $slaptopdown } {
            margin-top: 2rem;
            padding-bottom: 3rem;
        }
    }

    &--header {
        h3 {
            font-size: 1.75rem; //28px
            font-weight: 600;
            line-height: 34px;
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: $gray;
            margin-top: 0.5rem;
        }
    }
}

.getting-started.redbg {
    position: relative;
    background: $secondary;
    padding: 100px 1.5rem 0;

    .getting-started--nav {
        background: $secondary;

        .topnav-right {
            p {
                color: #ffffff;
            }
            a {
                color: #ffffff;
            }
        }
    }
}

//CHOOSE PLAN
.choose-plan {
    position: relative;

    &__header {
        h1 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 39px;
            color: #ffffff;
        }

        p {
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 22px;
            margin-top: 0.5rem;
            color: rgba($color: $off-white, $alpha: 0.85);
        }
    }

    &__main {
        position: relative;
        margin-top: 3.5rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{ $slaptop } {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

//PLAN DETAILS
.plan-details {
    position: relative;

    &__body {
        position: relative;
        margin-top: 2rem;

        @media #{ $mediumup } {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__accordion {
        margin-top: 1.5rem;
    }

    &__main {
        position: relative;
        flex: 1;
        max-width: 675px;
        width: 100%;
        margin-right: 2.5rem;

        .__section {
            position: relative;
            margin-top: 3.5rem;
            &:first-child {
                margin-top: 0;
            }
        }

        .accordion {
            position: relative;
            &__item {
                position: relative;
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__heading {
                padding: 0 1.5rem;
                height: 56px;
                display: flex;
                align-items: center;
                background: $off-white;
                border-radius: 4px;
                box-sizing: border-box;
            }

            &__button {
                width: 100%;
                height: inherit;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 20px;
                text-transform: uppercase;
                cursor: pointer;

                &[aria-expanded="true"] {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            &__panel {
                position: relative;
                padding: 1.5rem 1.5rem 1rem;
                // padding-left: 1.5rem;

                ul li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 1rem;

                    svg {
                        margin-right: 0.875rem;
                        color: #000b14;
                    }

                    p {
                        flex: 1;
                    }
                }
            }
        }

        .selectcard-grid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 1.5rem;
            margin-top: 1.5rem;

            @media #{ $largeup } {
                grid-template-columns: repeat(2, 1fr);
            }

            @media #{ $slaptop } {
                grid-template-columns: repeat(3, 1fr);
            }

            .card-check {
                margin-bottom: 0;
            }
        }
    }

    &__aside {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 2rem;

        @media #{ $mediumup } {
            flex: 1;
            position: -webkit-sticky;
            position: sticky;
            z-index: 1;
            top: 60px;
            right: 0;
            align-self: flex-start;
            flex-direction: column;
        }

        @media #{ $largeup } {
            max-width: 400px;
            width: 100%;
        }
    }

    &__summary {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
        border-radius: 10px;

        @media #{ $mediumdown } {
            margin-top: 2.5rem;
        }

        .__top {
            position: relative;
            padding: 2rem 1rem 0;
            background-image: linear-gradient($primary-light-1 90%, #fff 0);
            border-radius: 10px 10px 0px 0px;
            text-align: center;

            &--title {
                position: relative;
                font-size: 1rem;
                font-weight: 500;
                line-height: 19px;
                text-transform: uppercase;
                color: $gray;
            }

            &--value {
                position: relative;
                font-size: 2rem;
                font-weight: 600;
                line-height: 39px;
                margin-top: 0.625rem;

                .__period {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    color: $gray;
                }
            }

            &--worth {
                position: relative;
                display: inline-block;
                background: $f-blue;
                border-radius: 100px;
                min-width: 240px;
                padding: 0.5rem 1rem;
                margin-top: 1.5rem;

                p {
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 17px;
                    color: #ffffff;
                }
            }
        }

        .__header {
            position: relative;
            padding: 1.5rem 1.5rem 1rem;
            border-bottom: 1px solid #f5f5f5;

            h3 {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 17px;
                text-transform: uppercase;
            }
        }

        .__body {
            padding: 0rem 1.5rem 1.875rem;

            ul {
                margin-top: 1.5rem;
            }

            .confirm-deets-modal__terms {
                width: 100%;
            }
        }

        .__bottom {
            position: relative;
            padding: 2.5rem 1.5rem;

            &--title {
                position: relative;
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 17px;
                text-transform: uppercase;
                color: $gray;
            }

            ul {
                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    &__kyc {
        position: relative;
        margin-top: 2.5rem;
        padding: 1.5rem;
        background: #f8fcff;
        border-radius: 8px;
        font-size: 0.875rem;
        line-height: 21px;

        &--top {
            position: relative;
            display: flex;
            flex-wrap: nowrap;

            p {
                position: relative;
                flex: 1;
                margin-top: -4px;
            }
        }

        ul {
            position: relative;
            margin-top: 1.5rem;
            list-style: disc;
            padding-inline-start: 1.5rem;

            li {
                line-height: 28px;
            }
        }
    }
}
