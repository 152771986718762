//Variables
    //author: @Anisat Yunusa

// Colors
$primary: #FF8788;
$primary-light-1: #FFF3F3;
$primary-light-2: #FFFCFC;

$secondary: #3D0814;

$black: #000B14;
$gray: #5C6368;
$off-white: #F5F5F5;

$success: #3BAD54;
$success-light: #EBF7EE;
$pending: #F79F1A;
$pending-light: #FEF1DD;
$pending2: #543303;
$danger: #E0063C;

$f-blue: #005397;
$f-blue-light: #F5FBFF;
$f-lime: #E7F9A9;
$f-green: #9A9B73;

$stroke-alt: rgba(#D3D3D3, 0.4);

$dropshadow:  0px 0px 10px rgba(0, 11, 20, 0.04);
$borderinput: 1px solid $stroke-alt;
$borderline: 1px solid $off-white;


//RADIUS
$preferredradius: 8px;
$radius-c: 50%;
$radius-10: 10px;


// MEDIA QUERIES
$xxsmallup: 'only screen and (min-width: 312px)';
$xxsmalldown: 'only screen and (max-width: 320px)';
$xsmallup: 'only screen and (min-width: 368px)';
$xsmalldown: 'only screen and (max-width: 368px)';
$xs-md-up: 'only screen and (min-width: 480px)';
$xs-md-down: 'only screen and (max-width: 480px)';
$smallup: 'only screen and (min-width: 576px)';
$smalldown: 'only screen and (max-width: 575.98px)';
$mediumup: 'only screen and (min-width: 768px)';
$mediumdown: 'only screen and (max-width: 767.98px)';
$largeup: 'only screen and (min-width: 992px)';
$largedown: 'only screen and (max-width: 991.98px)';
$slaptop: 'only screen and (min-width: 1200px)';
$slaptopdown: 'only screen and (max-width: 1200px)';
$xlargeup: 'only screen and (min-width: 1199.98px)';
$xxlargeup: 'only screen and (min-width: 1440px)';
