//Utilities

.status-completed {
    color: $success;
    background: $success-light;
    border-radius: 100px;
}

.status-pending {
    color: $pending;
    background: $pending-light;
    border-radius: 100px;
}

.uploadedItems {
    position: relative;
    margin-top: 2.5rem;
}

.item-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    padding: 0.625rem 1rem;
    background: #fcfbfb;
    border-radius: 8px;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }

    @media #{ $largeup } {
        padding: 0.625rem 1.5rem;
    }

    @media #{ $xsmalldown } {
        display: block;
        align-items: unset;
        justify-content: unset;
    }
    &__left,
    &__right {
        position: relative;
        display: flex;
        align-items: center;
        @media #{ $xsmalldown } {
            justify-content: center;
        }
    }

    &__left {
        .filename {
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.004em;
            margin-left: 1rem;
        }
    }

    &__right {
        color: $gray;
        @media #{ $xsmalldown } {
            margin-top: 1.125rem;
        }

        svg {
            width: 12px;
            height: 12px;
            margin-left: 1rem;
            cursor: pointer;
            transition: color 0.25s ease-in-out;
            &:hover {
                color: $primary;
            }

            @media #{ $largeup } {
                margin-left: 1.5rem;
            }
        }
    }
}

.password-rules {
    position: relative;
    margin-top: 24px;
    max-width: 340px;

    .lineIndicator {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.25rem;

        .div {
            height: 2px;
            background-color: #ced4da;
        }

        .div.passed {
            background-color: $success;
        }
    }

    .actualRules {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        margin-top: 1.5rem;
        @media #{ $xsmalldown } {
            grid-template-columns: repeat(1, 1fr);
        }

        .rule {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 15px;
            color: $gray;
            display: flex;
            align-items: center;

            &.passed {
                color: $black;
            }

            .circle {
                width: 10px;
                height: 10px;
                background-color: #ced4da;
                border-radius: 100%;
                margin-right: 0.5rem;
            }
        }
    }
}

.add-banner {
    margin-bottom: 20px;
    .renewal-banner {
        display: flex;
    }
}

.renewal-banner {
    display: none;
    z-index: 1;
    width: 100%;
    background-color: #ffdaa1;
    padding: 1.25rem 1.5rem;

    @media #{ $largedown } {
        grid-gap: 1.25rem;
    }

    @media #{ $mediumup } {
        display: none;
        align-items: center;
        justify-content: center;
        grid-gap: 2rem;
    }

    p {
        position: relative;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 19px;
        color: $black;

        @media #{ $largeup } {
            font-size: 1rem;
        }
    }

    button {
        color: $f-blue;
        border: 1px solid $f-blue;
        height: 32px;
        &:hover {
            background-color: $f-blue-light;
        }
    }
}

// Display
.d-none {
    display: none;
}
.d-blk {
    display: block;
}

.d-iblk {
    display: inline-block;
}

.d-flx,
%flx-space {
    display: flex;
}

.d-iflx {
    display: inline-flex;
}

.flx-inverse {
    flex-direction: row-reverse;
    &--medium {
        @media #{ $mediumup } {
            flex-direction: row-reverse;
        }
    }
}

.flex-col {
    flex-direction: column;
    &--medium {
        @media #{ $mediumdown } {
            flex-direction: column;
        }
    }
    &--small {
        @media #{ $smalldown } {
            flex-direction: column;
        }
    }
}

.flex-auto {
    flex: auto;
}

.flex-wrap {
    flex-wrap: wrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.flex-nowrap {
    flex-wrap: nowrap;
}

.al-i-fs {
    align-items: flex-start;
}

.al-i-fe {
    align-items: flex-end;
}

.al-i-c {
    align-items: center;
}

.al-s-e {
    align-self: flex-end;
}

.al-s-bl {
    align-self: baseline;
}

.j-c-sb,
%flx-space {
    justify-content: space-between;
    &--small {
        @media #{ $smalldown } {
            justify-content: space-between;
        }
    }
}

.j-c-c {
    justify-content: center;
}

.j-c-fe {
    justify-content: flex-end;
}

.j-c-e {
    justify-content: end;
}

.d-flx-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flx-space {
    &--center {
        @extend %flx-space;
        align-items: center;
    }

    &--baseline {
        @extend %flx-space;
        align-items: baseline;
    }

    &--fe {
        @extend %flx-space;
        align-items: flex-end;
    }
}

.flx-center {
    @extend .d-flx;
    @extend .al-i-c;
}

.flx-top {
    @extend .d-flx;
    @extend .al-i-c;
}

.iflx-center {
    @extend .d-iflx;
    @extend .al-i-c;
}

.iflx-center-center {
    @extend .d-iflx;
    @extend .al-i-c;
    @extend .j-c-c;
}

.flx-j-center {
    @extend .d-flx;
    @extend .j-c-c;
}

.flx-center-center {
    @extend .d-flx;
    @extend .al-i-c;
    @extend .j-c-c;
}

.flx-center-col {
    @extend .d-iflx;
    @extend .j-c-sb;
    @extend .flex-col;
}

.flx-center-center-col {
    @extend .d-iflx;
    @extend .al-i-c;
    @extend .j-c-c;
    @extend .flex-col;
}

.flx-center-wrap {
    @extend .flx-center;
    flex-wrap: wrap;
}

.flx-space-wrap {
    @extend .d-flx-space;
    flex-wrap: wrap;
}

.flx-space-wrap-auto {
    @extend .d-flx;
    @extend .flex-wrap;
    @extend .flex-auto;
    @extend .j-c-sb;
}

.flx-1 {
    flex: 1;
}

// Margins
.mx-auto {
    margin-right: auto;
    margin-left: auto;
}

.m-0 {
    margin: 0 !important;
}

.mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mr-6 {
    margin-right: 0.375rem;
}

.mr-8 {
    margin-right: 0.5rem;
}

.mr-14 {
    margin-right: 0.875rem;
}

.ml-4 {
    margin-left: 0.25rem;
}

.ml-8 {
    margin-left: 0.5rem;
}

.ml-16 {
    margin-left: 1rem;
}

.mt-4 {
    margin-top: 0.25rem;
}

.mt-8 {
    margin-top: 0.5rem;
}

.mt-16 {
    margin-top: 1rem;
}

.mt-24 {
    margin-top: 1.5rem;
}

.mt-32 {
    margin-top: 2rem;
}

.mt-40 {
    margin-top: 2.5rem;
}

.mt-64 {
    margin-top: 4rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-32 {
    margin-bottom: 2rem;
}

.mb-40 {
    margin-bottom: 2.5rem;
}

// Paddings
.p-0 {
    padding: 0 !important;
}

.px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pr-8 {
    padding-right: 0.5rem;
}

.pl-8 {
    padding-left: 0.5rem;
}

.pt-8 {
    padding-top: 0.5rem;
}

.pb-8 {
    padding-bottom: 0.5rem;
}

// Media Query Utilities
.hide-on-mobile {
    @media #{ $smalldown } {
        display: none !important;
    }
}
.hide-on-medium {
    @media #{ $mediumdown } {
        display: none !important;
    }
}

.show-smalldown {
    display: none;
    @media #{ $smalldown } {
        display: block;
    }
}

.show-mediumdown {
    display: none;
    @media #{ $mediumdown } {
        display: block;
    }
}

.show-smallup {
    display: none;
    @media #{ $smallup } {
        display: block;
    }
}

.show-mediumup {
    display: none;
    @media #{ $mediumup } {
        display: block;
    }
}

.hide-mediumup {
    display: block;
    @media #{ $mediumup } {
        display: none;
    }
}

.hide-largeup {
    display: block;
    @media #{ $largeup } {
        display: none;
    }
}

.show-largeup {
    display: none;
    @media #{ $largeup } {
        display: block;
    }
}

// Screen Reader Text
// The .sr class ensure element is available in the DOM but only to screen readers. The whitespace: nowrap rule ensures accessible texts aren't smushed off screen.
// @link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.sr:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    border: 0;
    clip-path: inset(100%);
    overflow: hidden;
    white-space: nowrap;
}
