.all__benefit-list {
    position: relative;
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
    width: 100%;

    @media #{ $mediumup } {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    @media #{ $slaptop } {
        grid-template-columns: repeat(3, 1fr);
    }

    .card-benefit {
        height: 162px;
    }

    .card-benefit__info {
        padding-top: 1rem;
        margin-bottom: 16px;
    }

    .__value--actual {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: 14px;
    }

    .__title {
        text-transform: capitalize;
        font-weight: normal;
    }

    .__premium {
        font-size: 12px;
        font-weight: 500;
    }

    .__value {
        margin-bottom: 17.5px;
        margin-top: 0;
    }

    .__price {
        b {
            font-size: 18px;
        }

        span {
            color: #5c6368;
        }
    }

    .card-benefit__link--btn {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
        width: 50%;
        text-align: center;
        display: block;

        &:first-child {
            border-bottom-right-radius: 0;
            border-right: 1px solid #fdd;
        }

        &:nth-child(2) {
            border-bottom-left-radius: 0;
        }
    }
}
.all__gym-list {
    position: relative;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;

    @media screen and (min-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    @media screen and (min-width: 1350px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .card-gym {
        border-radius: 10px;
        border: 1px solid #d3d3d3;
        height: 360px;
        &__info {
            position: relative;
            .flance-pass {
                display: flex;
                position: absolute;
                top: 20px;
                right: 20px;
                background: rgba(219, 239, 255, 0.9);
                border-radius: 4px;
                padding: 4px 10px;
                align-items: flex-end;
                gap: 4px;

                > p {
                    font-family: "Be Vietnam Pro", sans-serif;
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 15.18px;
                    letter-spacing: 0.02em;
                    text-align: left;
                }
            }
        }

        @media #{ $xs-md-down } {
            height: fit-content;
        }
    }
    .card__img {
        height: 8.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .cover {
            width: 100%;
            height: 100%;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            object-fit: cover;
        }
    }
    .no-cover {
        background-color: black;
        border-radius: none;
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;

        .logo-image {
            border-radius: 4px;
            width: 85px;
            height: 85px;
        }
    }

    .card .__value--actual {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: 14px;
    }
    .__details {
        // border: 1px solid black;
        padding: 10px 1.25rem;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        height: calc(365px - 8.5rem);
        justify-content: space-between;
        @media #{$smalldown} {
            height: fit-content;
        }

        .gym__info {
            display: flex;
            align-items: center;
            gap: 12px;

            .img__container {
                width: 60px;
                height: 50px;
                > img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
            .gym__name {
                > h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 21.78px;
                    letter-spacing: 0.02em;
                }
                > p {
                    font-size: 0.875rem;
                    font-weight: 700;
                    line-height: 19.36px;
                    letter-spacing: 0.02em;
                    color: #005397;
                    > span {
                        font-size: 0.875rem;
                        font-weight: 600;
                        line-height: 16.94px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #5c6368;
                        text-decoration: line-through;
                    }
                }
            }
        }
        .amenities {
            margin-top: 12px;
            display: flex;
            align-items: baseline;
            ul {
                list-style-type: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                gap: 7px;
                row-gap: 0px;
                li {
                    position: relative;
                    padding-left: 12px;
                    margin-bottom: 6px;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: center;
                    color: #5c6368;

                    &:first-child {
                        &::before {
                            content: none;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: #5c6368;
                    }
                }
            }
        }
    }

    .claim--btn {
        margin-bottom: 10px;
        @media #{$smalldown} {
            margin-top: 16px;
        }
    }
    .__location {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 400 !important;
        line-height: 16.94px !important;
        letter-spacing: 0.02em;
        text-align: left;
        color: #000b14 !important;
        gap: 0.5rem;
        margin-top: 0.6rem;
    }

    .__price {
        b {
            font-size: 18px;
        }

        span {
            color: #5c6368;
        }
    }

    .card-benefit__link--btn {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
        width: 50%;
        text-align: center;
        display: block;

        &:first-child {
            border-bottom-right-radius: 0;
            border-right: 1px solid #fdd;
        }

        &:nth-child(2) {
            border-bottom-left-radius: 0;
        }
    }
}

.details__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 900px) {
        flex-direction: column;
    }
    &--right {
        width: 65%;
        @media (max-width: 900px) {
            width: 100%;
        }
        .slider_image {
            max-width: 360px;
            height: 280px;
            @media #{$mediumdown} {
                max-width: 260px;
                height: 220px;
            }

            > img {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
            }
        }
    }
    &--left {
        @media (max-width: 900px) {
            width: 100%;
        }
        .info-banner {
            background: #f8fcff;
            width: 24rem;
            svg {
                color: #005397;
            }
        }
    }
}

.mobile__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    @media #{$smallup} {
        display: none;
    }

    .address__container {
        display: flex;
        gap: 6px;
        .img__container {
            height: 53px;
            width: 57px;
            > img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }
        }
        .address {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > h3 {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 16.94px;
                text-align: left;
                color: $black;
            }
            > p {
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 14.52px;
                text-align: left;
                color: $gray;
            }
        }
    }
    .contact {
        display: flex;
        gap: 12px;
    }
}

.slider__container {
    margin-bottom: 60px;
    @media #{$smalldown} {
        display: none;
    }
}
.mobile__slider {
    margin-bottom: 60px;
    @media #{$smallup} {
        display: none;
    }
    .slider_image {
        max-width: 100%;
        height: 200px;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.planDeets-body {
    // margin-top: 32px;
    display: grid;
    // flex-wrap: wrap;
    gap: 20px;

    width: 100%;
    padding-bottom: 40px;
    @media (min-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.benefit__card {
    // width: 355px;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    padding: 22px 20px;
    height: fit-content;
    transition: all 0.2s;

    &--header {
        display: flex;
        align-items: center;
        gap: 12px;

        .header__content {
            width: 100%;
            .name {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                > h2 {
                    font-size: 1.125rem;
                    font-weight: 700;
                    line-height: 21.78px;
                    color: $black;
                    text-transform: capitalize;
                }
                > span {
                    svg {
                        opacity: 0.7;
                        @media #{ $smallup } {
                            display: none;
                        }
                    }
                }
            }
            .price {
                width: 100%;
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 24.2px;
                letter-spacing: 0.02em;
                color: #005397;
                margin-top: 10px;

                span {
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 13px;
                    letter-spacing: 0.03em;
                }
            }
            .discount {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 16.94px;
                letter-spacing: 0.02em;
                color: #5c6368;
                text-decoration: line-through;
                margin-top: 6px;
            }
        }
    }
    hr {
        border: 0px;
        border-bottom: 1px solid #d3d3d3;
        margin-top: 15px;
    }

    &--body {
        hr {
            border: 0px;
            border-bottom: 1px solid #d3d3d3;
            margin-top: 30px;
            @media #{$smallup} {
                display: none;
            }
        }
        > h2 {
            margin-top: 20px;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 21.78px;
            text-align: left;
            color: $black;
        }
        .details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 12px;
            > p {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #5c6368;
            }
            > span {
                margin-top: -7px;
            }
        }
    }

    &--bottom {
        .buttons {
            display: flex;
            margin-top: 16px;
            // padding-top: 17px;
            align-items: center;
            border-radius: 10px;
            justify-content: space-between;
            gap: 20px;
        }
        .hide {
            // width: 60% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            > p {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 19.36px;
                letter-spacing: 0.02em;
                text-align: center;
                color: $primary;
            }
            > span {
                margin-top: -7px;
            }
        }
        .details {
            width: 50%;
            background-color: #f5f5f5;
            display: flex;
            height: 40px;
            border-radius: 8px;
            gap: 10px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            > p {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 19.36px;
                letter-spacing: 0.02em;
                text-align: center;
                text-transform: uppercase;
                color: #3d0814;
            }
        }
        .claim__btn {
            width: 50%;
        }
    }
}

.extraHeight {
    height: 200px;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media #{$smalldown} {
        height: fit-content;
    }
}
.bestOffer {
    border: none;
    background: #3d0814;
    .benefit__card--header {
        .header__content {
            .name {
                h2 {
                    color: #fff;
                }
                .best-offer {
                    > p {
                        background-color: #fff3f3;
                        padding: 8px 24px;
                        border-radius: 100px;
                        text-transform: capitalize;
                        color: #3d0814;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 16.94px;
                        text-align: center;
                    }
                }
                > span {
                    display: none;
                }
            }
            .price {
                color: #ff8788;
            }
            .discount {
                color: #efd6d6;
            }
        }
    }
    hr {
        border-bottom: 1px solid #653838;
    }
    .benefit__card--body {
        h2 {
            color: #fff;
        }
        .details {
            > p {
                color: #fff;
            }
        }
    }
    .benefit__card--bottom {
        .buttons {
            width: 100%;
        }
        hr {
            border-bottom: 1px solid #653838;
        }
        .details {
            background: transparent;
            > p {
                color: #ff8788;
            }
            svg {
                color: #ff8788;
            }
        }
        @media #{$smalldown} {
            display: flex;
            .details {
                gap: 2px;
                align-items: center;
                p {
                    font-size: 0.875rem;
                    font-weight: 600;
                    line-height: 16.94px;
                    letter-spacing: 0.02em;
                    text-align: center;
                }
            }
        }
    }
    .lg-screen {
        @media #{$smalldown} {
            display: flex;
            .details {
                gap: 10px;
                align-items: center;
                p {
                    font-size: 0.875rem;
                    font-weight: 600;
                    line-height: 16.94px;
                    letter-spacing: 0.02em;
                    text-align: center;
                }
            }
        }
    }
    .sm-screen {
        display: none;
    }
}
.lg-screen {
    @media #{$smalldown} {
        display: none;
    }
}
.sm-screen {
    .details {
        gap: 2px;
        align-items: center;
        > p {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 16.94px;
            letter-spacing: 0.02em;
            text-align: center;
        }
        span {
            svg {
                color: $black;
            }
        }
    }
    @media #{$smallup} {
        display: none;
    }
}

.benefit__overview {
    width: 23.75rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 11, 20, 0.04);

    &--header {
        > .img__container {
            width: 100%;
            height: 10.5rem;
            border-top-right-radius: 0.625rem;
            border-top-left-radius: 0.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                width: 100%;
                height: 100%;
                border-top-right-radius: 0.625rem;
                border-top-left-radius: 0.625rem;
                object-fit: cover;
            }
        }
    }
    &--body {
        padding: 1.25rem 1rem;

        > h3 {
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 24.2px;
        }
        .__location {
            display: flex;
            justify-content: space-between;
            margin-top: 6px;
            align-items: center;
            > p {
                width: 70%;
                color: #5c6368;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
            }
            > span {
                display: flex;
                gap: 10px;
            }
        }
        hr {
            border-top: none;
            border-bottom: 1px solid #d3d3d3;

            margin: 1.25rem 0rem;
        }
        .operating__time {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            p {
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: $gray;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
        .gym__perks {
            &--tab {
                display: flex;
                justify-content: space-between;
                .perks {
                    padding: 12px 16px;
                    background: #f5f5f5;
                    border-radius: 50px;
                    cursor: pointer;
                    p {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 16.94px;
                        text-align: left;
                    }
                }
                .active {
                    background-color: #005397;
                    color: #fff;
                }
            }
            &--details {
                margin-top: 24px;
                > p {
                    display: flex;
                    justify-content: space-between;

                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 16.94px;
                    color: $gray;
                    margin-bottom: 20px;
                    align-items: center;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    li {
                        background-color: #f5f5f5;
                        padding: 6px 12px;
                        border-radius: 4px;
                        color: $gray;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: center;
                    }
                }
            }
        }
    }
    &--footer {
        padding-bottom: 20px;

        a {
            span {
                width: 24px;
                height: 24px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            display: flex;
            align-items: center;
            gap: 6px;
            color: #005397;
            font-size: 0.875;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            padding-left: 18px;
            padding-bottom: 12px;
        }
        .map {
            display: flex;
            justify-content: center;

            // > a {
            > iframe {
                width: 328px;
                height: 234px;
                border-radius: 12px;
            }
            // }
        }
    }
}
.benefit__overview.sm-screen {
    width: 100%;
    box-shadow: none;
    .operating__time {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        p {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: $gray;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    .benefit__overview--body {
        padding: 0;
    }
    .gym__perks {
        &--tab {
            display: flex;
            justify-content: space-between;
            .perks {
                padding: 8px 10px;
                background: #f5f5f5;
                border-radius: 50px;
                cursor: pointer;
                p {
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 14.94px;
                    text-align: left;
                }
            }
            .active {
                background-color: #005397;
                color: #fff;
            }
        }
        &--details {
            margin-top: 24px;
            > p {
                display: flex;
                justify-content: space-between;

                font-size: 0.75rem;
                font-weight: 400;
                line-height: 16.94px;
                color: $gray;
                margin-bottom: 20px;
                align-items: center;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                li {
                    background-color: #f5f5f5;
                    padding: 6px 12px;
                    border-radius: 4px;
                    color: $gray;
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: center;
                }
            }
        }
    }
    .map {
        width: 100%;
        border: 1px solid #d3d3d3;
        padding: 10px 20px;
        border-radius: 12px;

        a {
            span {
                width: 24px;
                height: 24px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            display: flex;
            align-items: center;
            gap: 6px;
            color: #005397;
            font-size: 0.75;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            // padding-left: 18px;
            padding-bottom: 12px;
        }
        > iframe {
            border: 1px solid #d3d3d3;
            border-radius: 12px;
            width: 100%;
            height: 287px;
        }
    }
}
.purchase {
    margin-bottom: 50px;
    .info-banner {
        svg {
            margin-right: 0.75rem;
            margin-top: 0px;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            color: #005397;
        }
    }
    .breakdown {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @media #{ $mediumdown } {
            flex-direction: column;
            gap: 20px;
        }

        .amount {
            display: flex;
            gap: 10px;
            p {
                font-size: 1rem;
                font-weight: 700;
                line-height: 19.36px;
                letter-spacing: 0.01em;
                text-align: left;
                color: $black;
            }
            span {
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 14.52px;
                letter-spacing: 0.01em;
                text-align: left;
                text-transform: uppercase;
            }
            .discount {
                color: #5c6368;
                text-decoration: line-through;
                font-size: 0.875rem;
                font-weight: 600;
            }
        }
        .membership {
            display: flex;
        }
    }
    > h4 {
        color: #5c6368;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
        letter-spacing: 0.004rem;
        margin-bottom: 1rem;
        margin-top: 30px;
    }
    .member-head {
        margin-top: 0px;
    }

    .purchase__option {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        // grid-gap: 1.5rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
            grid-row-gap: 0rem;
        }

        @media #{ $largeup } {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .purchase__form {
        .member__check {
            width: 70%;
            display: flex;
            justify-content: space-between;
            @media #{ $mediumdown } {
                width: 100%;
            }
            .check {
                display: flex;
                gap: 0.5rem;
                label {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.94px;
                    letter-spacing: 0.004em;
                    text-align: left;
                }
            }
        }
        .inputField {
            width: 70%;
            margin-top: 1.5rem;

            @media #{ $mediumdown } {
                width: 100%;
            }

            > input {
                padding: 1rem;
                width: 100%;
                border-radius: 0.5rem;
                border: 1px solid #d3d3d3;
                font-size: 1rem;
                color: #717171;
                &::placeholder {
                    color: #717171;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                    opacity: 0.8;
                }
            }
        }
    }
    .payment__option {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        // grid-gap: 1.5rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }

        @media #{ $largeup } {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.info-note {
    background: #f8fcff;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    gap: 13px;
    border-radius: 8px;

    svg {
        color: #005397;
        margin-top: 10px;
    }
    > p {
        text-align: left;
        font-size: 0.875rem;
        color: #000b14;
        font-size: 0.875rem;
    }
}

.subpage__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 20px;

    @media screen and (max-width: 820px) {
        font-size: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}
