//TEXT
//author: @Anisat Yunusa

.tx-c {
    text-align: center;
}

.tx-r {
    text-align: right;
}

.tx-medium {
    font-weight: 500;
}

.underline {
    text-decoration: underline;
    text-underline-position: under;
}

.uppercase,
.caps {
    text-transform: uppercase;
}

.section--header {
    position: relative;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 22px;

    @media screen and (max-width: 820px) {
        font-size: 1.125rem;
    }
}

.section--subheader {
    position: relative;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #5c6368;
}

.detail--descrip {
    margin-top: 2rem;
    padding-bottom: 1rem;

    &-overview {
        display: flex;
        align-items: center;
    }

    h3 {
        position: relative;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 29px;

        @media screen and (max-width: 820px) {
            font-size: 1rem;
        }
    }

    p {
        position: relative;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.004em;
        color: $gray;
        margin-top: 0.5rem;
        width: 100%;

        span.__value {
            color: $f-blue;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    .__filter {
        margin-top: 24px;
        ul {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            li {
                font-style: normal;
                font-weight: 500;
                font-size: 0.87rem;
                line-height: 17px;
                color: #3d0814;
                background: #f5f5f5;
                border-radius: 100px;
                padding: 8px 24px;
                cursor: pointer;
                transition: all 0.5s;
                &:hover {
                    background: darken($color: #f5f5f5, $amount: 9);
                }
            }
            .active {
                background: #005397;
                color: #ffffff;
                &:hover {
                    background: lighten($color: #005397, $amount: 5);
                }
            }
        }
    }

    &-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
        overflow: hidden;

        @media screen and (max-width: 820px) {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.section--text {
    position: relative;

    h3 {
        position: relative;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 22px;

        @media screen and (max-width: 820px) {
            font-size: 1rem;
        }
    }

    p {
        position: relative;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.004em;
        color: $gray;
        margin-top: 0.25rem;

        @media screen and (max-width: 820px) {
            font-size: 12px;
        }
    }
}
