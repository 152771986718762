//MAKE A CLAIM
    //author: @Anisat Yunusa


.make-claim {
    position: relative;

    &__body  {
        position: relative;
        margin-top: 2rem;
        

        @media #{ $mediumup } {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__main {
        position: relative;
        flex: 1;
        max-width: 675px;
        width: 100%;
        margin-right: 2.5rem;

        .inputgroup {
            @media (min-width: 768px) and (max-width: 991.98px) {
                flex-direction: column;
                &:last-child {
                    .form-group {
                        margin-bottom: 1.5rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .form-group:last-child {
                    margin-left: 0;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    &__aside {
        margin-top: 2.5rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
        border-radius: 10px;

        @media #{ $mediumup } {
            flex: 1;
            margin: 0;
            position: -webkit-sticky;
            position: sticky;
            z-index: 1;
            top: 160px;
            right: 0;
            align-self: flex-start;
        }

        @media #{ $largeup } {
            max-width: 390px;
            width: 100%;
            flex: unset;
        }

        .__top  {
            position: relative;
            padding: 1.5rem 1.5rem 1rem;
            border-bottom: 1px solid $off-white;

            p {
                font-size: .875rem;
                font-weight: 600;
                line-height: 17px;
                text-transform: uppercase;
            }
        }

        .__main {
            position: relative;
            padding: 1.5rem 1.5rem 2.5rem;

            &--deets {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-size: .875rem;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 1rem;
                    
                    &:first-child  {
                        color: $gray;
                    }
                }
            }
        }
    }
}