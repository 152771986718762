//SETTINGS
//author: @Anisat Yunusa

.settings {
    position: relative;
    display: flex;
    min-height: inherit;
    flex-direction: column;

    &__main {
        width: 100%;
        height: inherit;
        flex: 1;
        padding-top: 70px;
        @media screen and (max-width: 820px) {
            padding-top: 127px;
        }

        .back-btn {
            margin: 0;
            margin-bottom: 2.5rem;

            @media screen and (max-width: 991.98px) {
                display: none;
            }
        }

        &--body {
            position: relative;
            width: 100%;
        }

        &--section {
            margin-top: 4rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__wrap {
        width: 100%;
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        padding: 30px;
        margin-bottom: 32px;
        gap: 12px;

        @media screen and (max-width: 570px) {
            padding: 20px;
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 20px;
            @media screen and (max-width: 570px) {
                font-size: 1rem;
            }
        }
    }

    &__form {
        &-group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 30px;
            @media screen and (max-width: 570px) {
                gap: unset;
                margin-bottom: unset;
            }
            .form-group {
                flex: 1;

                &:last-child {
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 570px) {
                    flex: unset;
                    width: 100%;
                }
            }

            &-section {
                flex: 1;

                @media screen and (max-width: 570px) {
                    flex: unset;
                    width: 100%;
                    // margin-bottom: 30px;
                }
            }
        }

        &-action {
            width: fit-content;
            margin-left: auto;
            button {
                width: 100%;
            }
            @media screen and (max-width: 570px) {
                margin-bottom: 30px;
            }
            @media screen and (max-width: 960px) {
                width: 100%;
            }
        }
    }

    &__password {
        border-radius: 12px;
        background-color: #f5f5f5;
        padding: 30px;
        margin-right: 48px;

        @media screen and (max-width: 1057px) {
            margin-right: 20px;
        }

        @media screen and (max-width: 960px) {
            width: 100%;
            margin-right: 0;
            margin-top: 20px;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;
            text-align: left;
            margin-bottom: 12px;
        }

        li {
            color: #5c6368;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            span {
                display: block;
                width: 10px;
                height: 10px;
                background-color: #ced4da;
                border-radius: 100%;
                margin-right: 0.5rem;

                &.passed {
                    background-color: #3bad54;
                }
            }
        }
    }
}

//ACCOUNT
.setting-account {
    .imageUpload {
        position: relative;
        display: flex;
        align-items: center;

        .avatar-circle {
            margin-right: 1.5rem;
            width: 100px;
            height: 100px;

            @media screen and (max-width: 600px) {
                width: 50px;
                height: 50px;
            }
            .image-wrapper,
            &__placeholder {
                width: 96px;
                height: 96px;

                @media screen and (max-width: 600px) {
                    width: 46px;
                    height: 46px;
                }
            }

            &__initials {
                font-size: 1.875rem;
                line-height: 36px;
                @media screen and (max-width: 600px) {
                    font-size: 1rem;
                }
            }
        }

        .text-container {
            position: relative;

            h3 {
                position: relative;
                font-size: 1rem;
                font-weight: 500;
                line-height: 20px;
            }

            p {
                position: relative;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 15px;
                color: $gray;
            }
        }

        &__action {
            @media screen and (max-width: 570px) {
                width: 100%;
            }
            button {
                margin-left: auto;
                display: block;
                @media screen and (max-width: 570px) {
                    width: 100%;
                }
            }
            p {
                font-size: 12px;
                margin-top: 12px;
                color: #5c6368;
                @media screen and (max-width: 570px) {
                    text-align: center;
                    font-size: 10px;
                }
            }
        }
    }

    .deleteAccount .btn {
        color: $danger;
    }
}

.settings-linked,
.settings-subscription {
    position: relative;
    // padding-left: 1.5rem;
    padding-right: 0;

    @media #{ $largeup } {
        padding-left: 1.5rem;
    }

    @media #{ $slaptop } {
        padding-left: 2.5rem;
    }
}

//LINKED BANKS
.settings-linked {
    &__cards {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1.5rem;
        margin-top: 2rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }

        @media #{ $slaptop } {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__card {
        height: 130px;

        @media #{ $mediumdown } {
            height: unset;
            min-height: 130px;
        }

        @media #{ $smalldown } {
            height: unset;
        }
    }

    .addCard-btn {
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        color: $gray;
        transition: all 0.25s ease-in-out;

        &:hover {
            color: rgba($color: $black, $alpha: 0.8);
            background: rgba($color: $off-white, $alpha: 0.2);
        }

        p {
            position: relative;
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
        }
    }
}

//SUBSCRIPTION
.settings-subscription {
    &__cards {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1.5rem;

        @media #{ $mediumup } {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }
    }

    &__card {
        padding: 1.5rem;
        height: 190px;
        display: flex;
        flex-direction: column;

        @media #{ $slaptop } {
            padding: 2rem;
        }

        @media #{ $mediumdown } {
            height: unset;
            // min-height: 190px;
        }

        .btn {
            border-radius: 4px;
        }

        .__top {
            flex: 1;
        }
    }

    .currentPlan {
        @extend .card-white;

        .left {
            display: flex;
            justify-content: space-between;
        }

        .__top--title {
            position: relative;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 19px;
            text-transform: uppercase;
            color: $gray;
        }

        .__top--value {
            position: relative;
            margin-top: 1.5rem;
            font-size: 1.75rem;
            font-weight: 600;
            line-height: 34px;

            .__period {
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 17px;
                color: $gray;
            }
        }

        &__status {
            position: relative;
            padding: 2px;
            min-width: 90px;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 15px;
            text-align: center;
            color: $gray;
            background: $off-white;
            border-radius: 4px;
            // align-self: start;
        }

        .isActive {
            color: $danger;
            background: $primary-light-1;
        }
    }

    .explorePlans {
        background: $primary-light-2
            url("../../assets/svgs/wavyBg-faintPink.svg") no-repeat;
        border: 1px solid $primary-light-1;
        background-size: cover;
        border-radius: 10px;

        h3 {
            color: $black;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 24px;
        }

        p {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 20px;
            color: $gray;
            margin-top: 0.5rem;
        }

        svg {
            color: $primary-light-1;
        }

        .__top {
            flex: unset;
        }

        .__bottom {
            margin-top: 1.5rem;
        }

        .paymentMtd--card {
            @media #{ $xs-md-up } {
                display: flex;
                justify-content: space-between;
                height: 88px;
                padding: 18px 32px;
            }

            &__left {
                position: relative;
                display: flex;

                .cardDeets {
                    position: relative;
                    margin-left: 0.5rem;

                    h3 {
                        position: relative;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 19px;
                    }

                    .__expiry {
                        position: relative;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 17px;
                        color: $gray;
                        margin-top: 0.5rem;
                    }
                }
            }

            &__right {
                @media #{ $xs-md-down } {
                    margin-left: 2.5rem;
                    margin-top: 1.25rem;
                }

                .btn {
                    font-size: 14px;
                    line-height: 17px;
                    color: $secondary;
                    font-weight: 400;

                    &:hover {
                        color: darken($secondary, 3%);
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &__payMtds {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }

    &__history {
        svg {
            cursor: pointer;
            color: $gray;

            &:hover {
                color: $secondary;
            }
        }

        .mobileview-table {
            .invoice {
                font-weight: 500;
            }
        }
    }

    .paymentMtd {
        padding: 18px 32px;
        width: 100%;
        height: 88px;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        background: #fff;
        margin-bottom: 16px;

        &.activeState {
            border: 1px solid #ff8788;
            background: #fff3f3;
        }

        &__icon {
            height: 24px;
        }

        &__info {
            margin-left: 8px;

            h4 {
                font-weight: 500;
                color: #000b14;
                margin-bottom: 9px;
            }

            p {
                color: #5c6368;
                font-size: 14px;
            }
        }

        &__default {
            color: #3d0814;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 2px 14px;
            border-radius: 4px;
            background: #fffcfc;
            height: 20px;
            margin-left: 4px;
        }

        &__action {
            margin-left: auto;
            align-self: center;
            font-size: 14px;

            &-delete {
                color: #5c6368;
                font-size: 14px;
                margin-right: 16px;
            }

            &-default {
                font-size: 14px;
            }
        }
    }
}
