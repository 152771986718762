.mobnav {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 24px;
    right: 24px;
    background: #3d0814;
    width: calc(100% - 48px);
    padding: 12px;
    height: 68px;
    z-index: 100000;
    box-shadow: 0px 1px 5px 0px #00000008;
    border-radius: 8px;

    @media screen and (min-width: 390px) {
        height: 5rem;
    }

    @media #{$smallup} {
        display: none;
    }

    &__item {
        a,
        button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 9px;
            color: #fff3f3;
            text-align: center;
            font-weight: 500;
            border-radius: 4px;
            height: 100%;
            min-width: 53px;
            padding: 0 6px;

            @media screen and (min-width: 390px) {
                font-size: 0.625rem;
            }

            &.active-link {
                box-shadow: 0px 4px 4px 0px #00000040;
                background-color: #fff3f3;
                color: #ff8788;
            }

            img {
                width: 16px;
                height: auto;
                display: block;
                margin: 0 auto;
                margin-bottom: 4px;
            }

            span {
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__more {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 80px;
        background-color: #fff;
        z-index: 10000;
        padding: 24px 36px;
        display: block;

        @media screen and (min-width: 820px) {
            display: none;
        }

        &-item {
            margin-bottom: 12px;

            a,
            button {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #5c6368;
                padding: 11.5px 0px;

                &.active {
                    color: #ff8788;
                }

                img {
                    margin-right: 12px;
                    width: 18px;
                    height: auto;
                }
            }

            button {
                color: #e0063c;
            }
        }

        .sidebar__divider {
            position: relative;
            margin-bottom: 25px;
            margin-top: 20px;
            bottom: unset;
        }

        .sidebar__help {
            position: relative;
            bottom: unset;
            img {
                margin-right: 12px;
                width: 16px;
            }
            span {
                display: block;
            }
        }
    }

    &__logout {
        &-icon {
            width: fit-content;
            margin: 0 auto 20px;
        }

        h3 {
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 6px;
        }

        p {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            letter-spacing: 0.004em;
            text-align: center;
            color: #5c6368;
            margin-bottom: 30px;
        }

        button {
            width: 100%;
            height: 44px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 8px;

            &:first-of-type {
                background-color: #e0063c;
                color: #fff;
                margin-bottom: 20px;
            }

            &:last-of-type {
                background-color: #f5f5f5;
                color: #3d0814;
            }
        }
    }
}
