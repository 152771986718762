.illust {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
        color: #000b14;

        @media #{ $smalldown } {
            font-size: 16px;
        }
    }

    &__loading {
        width: 250px;
        margin: 20px auto;

        @media #{ $smalldown } {
            width: 125px;
        }
    }

    &__success {
        width: 165px;
        margin: 20px auto;

        @media #{ $smalldown } {
            width: 90px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        color: #5c6368;
        margin-bottom: 20px;

        @media #{ $smalldown } {
            font-size: 12px;
        }
    }

    &__loader {
        width: 64px !important;
        margin: 0px auto;

        @media #{ $smalldown } {
            width: 40px !important;
        }
    }
}
