//Header
//author: @Anisat Yunusa

.topnav {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 300;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.03);

    &__title {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.78px;
        text-align: left;
        color: #000000;
        display: none;
        @media screen and (min-width: 820px) {
            display: block;
        }
    }

    &--wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: inherit;
        max-width: 1480px;
        margin: auto;
        padding: 0 24px;

        @media screen and (min-width: 820px) {
            padding: 0 2.5rem;
        }
        @media #{ $smalldown } {
            flex-wrap: nowrap;
        }
    }

    .brand {
        position: relative;

        @media screen and (min-width: 820px) {
            display: none;
        }

        img {
            height: 30px;
            @media #{ $smalldown } {
                height: 24px;
            }
        }
    }

    .topnav-right {
        position: relative;
        padding: 1rem 0;
        cursor: pointer;
    }

    .username {
        position: relative;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        text-align: right;

        @media #{ $smalldown } {
            display: none;
        }
    }

    &__dropdown {
        position: absolute;
        z-index: 100;
        right: 0;
        top: 100%;
        padding: 20px;
        min-width: 180px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
        box-sizing: border-box;

        li {
            width: 100%;
            border-radius: 0;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.004em;
            color: $gray;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            padding: 0.5rem 1.5rem;

            &:hover {
                background: $off-white;
            }

            a,
            span {
                width: 100%;
                color: inherit;
                display: flex;
                align-items: center;
                font-size: 16px;
            }

            &.logoutBtn {
                span {
                    color: $danger;
                }
                margin-bottom: 0;
            }

            img {
                width: 24px;
                height: auto;
                margin-right: 12px;
            }
        }

        &-icon {
            display: none;
            @media #{$smallup} {
                display: inline-flex;
            }
        }
    }
}

.avatar-circle {
    position: relative;
    height: 48px;
    width: 48px;
    border-radius: 100%;
    background: $primary-light-2;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media #{$smallup} {
        margin-right: 1rem;
    }

    .image-wrapper,
    &__placeholder {
        position: relative;
        height: 44px;
        width: 44px;
        border-radius: 100%;
    }

    .image-wrapper img {
        position: relative;
        border-radius: 100%;
        width: 100%;
        object-fit: cover;
        height: 100%;
        max-height: 100%;
    }

    &__placeholder {
        background: $primary-light-1;
    }

    &__initials {
        position: relative;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        text-align: center;
    }
}

.back-btn {
    position: relative;

    button,
    a {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 17px;
        color: $gray;

        &:hover {
            color: $secondary;
        }

        svg {
            color: inherit;
            margin-right: 1rem;
        }
    }
}

.preBoltNav {
    background: $primary-light-2;
    box-shadow: none;
}
