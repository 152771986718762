//AUTH LAYOUT
//author: @Anisat Yunusa

.auth {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{ $mediumup } {
        display: flex;
        height: 100vh;
        overflow: hidden;
    }

    &__logo {
        position: absolute;
        top: 56px;
        left: 56px;
        @media #{ $mediumdown } {
            display: none;
        }
    }

    &__aside {
        position: relative;
        padding: 0 1.5rem;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        height: 100vh;

        @media #{ $mediumdown } {
            display: none;
        }

        @media #{ $mediumup } {
            width: 45%;
            background-color: $secondary;
        }

        @media #{ $slaptop } {
            width: 42%;
        }

        &--content {
            position: relative;
            width: 100%;
            margin: auto;
            margin-top: 50px;
            z-index: 1;
            @media #{ $mediumdown } {
                margin-top: 0rem;
            }
        }

        &--header {
            position: relative;
            margin-top: 4.25rem;

            @media #{ $mediumdown } {
                display: none;
            }

            h1 {
                font-size: 2.25rem;
                font-weight: 600;
                line-height: 54px;
                color: #ffffff;
                text-align: left;
                @media #{ $largedown } {
                    font-size: 1.875rem;
                    line-height: 40px;
                }
            }

            p {
                font-size: 1.125rem;
                font-weight: 500;
                line-height: 27px;
                color: #f5f5f5;
                margin-top: 1.5rem;
                text-align: left;
                @media #{ $largedown } {
                    font-size: 1rem;
                    line-height: 28px;
                }
            }
        }

        &--images {
            position: relative;
            @media #{ $mediumdown } {
                display: none;
            }
            .carousel__container {
                padding: 24px;
                backdrop-filter: blur(14px);
                width: 100%;
                // height: 220px;
                border-radius: 16px;
                // height: 300px;

                .testimonial__card {
                    width: 100%;
                    padding-top: 4.5rem;
                    // height: 220px;
                }
            }
            .primary {
                background-color: #fff3f3;
                padding: 10px 0;
                border-radius: 10px;
                width: 100%;
                margin-top: 2.5rem;
            }

            .images {
                position: relative;
                width: 65%;
                margin: auto;
                margin-top: 4.25rem;
                @media #{ $largeup } {
                    width: 55%;
                    margin-left: 23%;
                    margin-top: 5.25rem;
                }

                @media #{ $slaptop } {
                    width: 270px;
                }

                .notif {
                    position: absolute;
                    pointer-events: none;
                    z-index: 2;
                    box-shadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
                }

                .notif1 {
                    width: 180px;
                    right: -88px;
                    top: 45px;

                    @media #{ $slaptopdown } {
                        width: 120px;
                        right: -38px;
                        top: 25px;
                    }
                }
                .notif2 {
                    width: 180px;
                    left: -80px;
                    bottom: -27px;
                    @media #{ $slaptopdown } {
                        width: 120px;
                        left: -38px;
                        bottom: 20px;
                    }
                }

                .plancard {
                    position: relative;
                    width: 100%;
                    box-shadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
                }
            }
        }

        &-pattern {
            position: absolute;
            bottom: 0;
            left: 0;
            display: none;

            @media only screen and (min-height: 850px) {
                display: block;
            }
        }

        &-ellipse {
            width: 414px;
            height: auto;

            @media only screen and (min-height: 850px) {
                width: 100%;
            }

            &-wrap {
                position: absolute;
                left: 50%;
                margin-left: -207px;
                top: 150px;

                @media #{ $mediumdown } {
                    display: none;
                }

                @media only screen and (min-height: 850px) {
                    margin-left: -224.5px;
                }
            }
        }

        &-slider {
            text-align: center;
            margin-top: 92px;

            &-wrap {
                .slick-dots {
                    li {
                        height: 12px;
                        width: 12px !important;
                    }
                }
                .slick-dots .slick-active {
                    background-color: #ff8788;
                    width: 24px !important;
                    height: 12px;
                    border-radius: 8px;
                }
            }
            &-title {
                color: #fff;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 16px;
            }
            &-desc {
                color: #f5f5f5;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }
            &-sub {
                // margin-bottom: 50px;
                &-img {
                    margin: 0 auto;
                }
            }
            &-dots {
                width: 12px;
                height: 12px;
                background-color: rgba(255, 135, 136, 0.3);
                border-radius: 50%;

                @media screen and (max-width: 820px) {
                    width: 6px;
                    height: 6px;
                }
            }

            &-container {
                @media #{ $mediumdown } {
                    display: none;
                }
            }
        }
    }

    &__form {
        position: relative;
        flex: 1;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
        @media #{ $mediumdown } {
            width: 100%;
            padding: 0;
            height: inherit;
        }

        &--content {
            position: relative;
            max-width: 586px;
            width: 100%;
            padding: 2.25rem 0 3rem 0;

            &.signup {
                align-self: baseline;
            }

            @media #{ $mediumdown } {
                background-color: #3d0814;
                padding-top: 0rem;
                padding-bottom: 0rem;
                position: unset;
                max-width: 100% !important;
            }
        }

        &--header {
            position: relative;
            @media #{ $mediumdown } {
                padding: 3.75rem 1.5rem 1.25rem;
            }

            h3 {
                position: relative;
                font-size: 1.75rem; //28px
                font-weight: 600;
                text-align: center;
                @media #{ $mediumdown } {
                    color: #fff3f3;
                }
            }

            p {
                position: relative;
                font-size: 1rem;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.01em;
                color: $gray;
                margin-top: 6px;
                text-align: center;
                @media #{ $mediumdown } {
                    color: #fff3f3;
                }
                @media #{ $smalldown } {
                    font-size: 0.75rem;
                }
            }
        }
        &--altAuth {
            width: 100%;
            background-color: #fff;
            @media #{ $mediumdown } {
                padding: 0 3.5rem;
            }
            @media #{ $smalldown } {
                padding: 0 1.5rem;
            }
            .altText {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 24px;
                background-color: #fff;

                hr {
                    width: 35%;
                    height: 1px;
                    border: 1px solid rgba(211, 211, 211, 0.6);
                    @media #{ $smalldown } {
                        width: 30%;
                    }
                }
                p {
                    width: 30%;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: center;
                    color: #5c6368;
                    @media #{ $smalldown } {
                        width: 40%;
                    }
                }
            }
            .socials {
                display: flex;
                gap: 30px;
                margin-top: 20px;
            }
            .lg-screen {
                @media #{ $mediumdown } {
                    display: none !important;
                }
            }
            .sm-screen {
                @media #{ $mediumup } {
                    display: none;
                }
            }
            .altBtn {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
        .sso {
            position: relative;
            margin-top: 30px;

            .sso-signup-btn {
                gap: 1rem;
                margin-bottom: 30px;
            }

            .altText {
                display: flex;
                justify-content: space-between;
                align-items: center;

                background-color: #fff;

                hr {
                    width: 40%;
                    height: 1px;
                    border: 1px solid rgba(211, 211, 211, 0.6);
                }
                p {
                    width: 20%;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: center;
                    color: #5c6368;
                }
            }
            // p {
            //     position: relative;
            //     text-transform: uppercase;
            //     text-align: center;
            //     margin-top: 2rem;
            //     margin-bottom: 2rem;
            //     color: #000b14;

            //     &:before {
            //         content: "";
            //         display: block;
            //         width: 40%;
            //         height: 1px;
            //         background: rgba($color: $gray, $alpha: 0.3);
            //         left: 5%;
            //         top: 50%;
            //         position: absolute;
            //     }

            //     &:after {
            //         content: "";
            //         display: block;
            //         width: 40%;
            //         height: 1px;
            //         background: rgba($color: $gray, $alpha: 0.3);
            //         right: 5%;
            //         top: 50%;
            //         text-align: left;
            //         position: absolute;
            //     }
            // }
        }

        .__form {
            position: relative;
            width: 100%;
            margin-top: 3.5rem;
            background-color: #fff;

            @media #{ $mediumdown } {
                padding: 0 3.5rem;
                margin: auto;
                margin-top: 2.5rem;
            }
            @media #{ $smalldown } {
                padding: 0 1.5rem;
                font-size: 0.75rem;
            }

            &-signup {
                margin-top: 2.5rem;
                @media #{ $mediumdown } {
                    margin-top: 0;
                    padding-top: 1.8rem;
                    background-color: #fff;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
        }

        .extralinnk {
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            color: $gray;
            background-color: #fff;
            padding-top: 32px;
        }

        .acknwmnt {
            position: relative;
            max-width: 460px;
            width: 100%;
            margin: auto;
            padding-top: 2rem;
            padding-bottom: 3rem;
            font-size: 0.75rem;
            line-height: 18px;
            letter-spacing: 0.02em;
            background-color: #fff;
            color: $gray;

            @media #{ $mediumdown } {
                max-width: 100%;
                width: 100%;
                @media #{ $mediumdown } {
                    padding-right: 3.5rem;
                    padding-left: 3.5rem;
                }
                @media #{ $smalldown } {
                    padding-right: 1.5rem;
                    padding-left: 1.5rem;
                }
            }

            a {
                font-size: inherit;
                color: inherit;
                &:hover {
                    color: $secondary;
                }
            }
        }
    }

    &__success {
        position: relative;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 2rem 0;
        @media #{ $mediumdown } {
            padding-top: 4rem;
        }

        &-container {
            max-width: 520px;
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 24px;
            margin-top: 2rem;
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            color: $gray;
            margin-top: 1rem;
        }
    }
}
.lg_sso {
    @media #{ $mediumdown } {
        display: none;
    }
}

.sm_sso {
    @media #{ $mediumup } {
        display: none;
    }
}
.forgot-password {
    text-align: center;
    display: flex;
}
.verify--mail {
    .auth__form--content {
        @media #{ $mediumdown } {
            background-color: #fff;
            padding-top: 3rem;
            padding-bottom: 0rem;
            margin: 0 auto;
            max-width: 95% !important;
        }
    }
}
