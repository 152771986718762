//abstracts
@import "abstracts/variables";
@import "abstracts/base";
@import "abstracts/utilities";
@import "abstracts/colors";
@import "abstracts/text";

//Components
@import "components/inputs";
@import "components/cards";
@import "components/button";
@import "components/tables";
@import "components/modal";
@import "components/selectcard";
@import "components/emptyState";
@import "components/loader";
@import "components/sidemodal";
@import "components/slider";
@import "components/tooltip";
@import "components/imageLoop";
@import "components/banner";
@import "components/renewal";
@import "components/illustrationLoader";

//Layout
@import "layouts/navigation";
@import "layouts/gettingStartedLayout";
@import "layouts/authlayout";
@import "layouts/dashboardLayout";
@import "layouts/sidenav";
@import "layouts/dashboard-sidebar";
@import "layouts/mobile-nav";

//Pages
@import "pages/home";
@import "pages/explore";
@import "pages/accountSetup";
@import "pages/benefitdetails";
@import "pages/beneficiary";
@import "pages/makeClaim";
@import "pages/settings";
@import "pages/404";
@import "pages/benefits";
@import "pages/benefits";
@import "pages/billings";
@import "pages/tax";
@import "pages/flancepass";

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#nprogress {
    .bar {
        background: #ff8788 !important;
    }

    .spinner-icon {
        border-top-color: #ff8788 !important;
        border-left-color: #ff8788 !important;
    }
}

.info-banner {
    position: relative;
    padding: 1rem 1.5rem;
    display: flex;
    background: #f8fcff;
    border-radius: 8px;

    @media #{ $xsmalldown } {
        flex-direction: column;
        padding: 1rem;
    }

    svg {
        color: #005397;
        width: 20px;
        margin-right: 0.75rem;
        margin-top: 5px;

        @media #{ $xsmalldown } {
            margin-top: 0;
            margin-bottom: 0.625rem;
        }
    }

    p {
        flex: 1;
        position: relative;
        font-size: 0.875rem;
        line-height: 21px;

        @media #{ $xsmalldown } {
            font-size: 0.75rem;
        }

        a {
            font-size: inherit;
        }
    }

    span.bold {
        font-weight: 600;
    }
}

.react-tooltip {
    border-radius: 10px !important;
    padding: 8px !important;
    opacity: 0.95 !important;
}

.slick-dots {
    li {
        button {
            width: 10px !important;

            &::before {
                width: 10px !important;

                color: #ff8788 !important;
                margin-top: -2px;
                @media #{ $mediumdown } {
                    margin-top: -6px;
                }
            }
        }
    }
    li.slick-active {
        &::before {
            color: #ff8788 !important;
        }
    }
}

.slick-prev {
    z-index: 1;
    left: 25px !important;
    &::before {
        cursor: pointer;
        font-size: 50px !important;
        color: rgba(255, 255, 255, 0.8) !important;
    }
}
.slick-next {
    z-index: 1;
    right: 60px !important;
    cursor: pointer !important;
    &::before {
        font-size: 50px !important;
        color: rgba(255, 255, 255, 0.8) !important;
    }
}
