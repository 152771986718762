.image-container {
    position: relative;
    width: auto;
    height: 380px;
    overflow: hidden;

    @media only screen and (min-height: 832px) {
        height: 449px;
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}
