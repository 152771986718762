//Home
//author: @Anisat Yunusa

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.add-banner {
    .home {
        padding-top: 3.5rem + 4.5rem;

        @media #{ $mediumdown } {
            padding-top: 3rem + 8rem;
        }
    }
}

.loan-banner {
    position: relative;
    margin-bottom: 1.5rem;
    background: $pending-light;
    border-radius: 10px;
    border: 1px dashed $pending;
    color: $pending2;
    padding: 0.75rem;
    box-sizing: border-box;

    @media #{ $smallup } {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 56px;
    }

    @media #{ $mediumdown } {
        padding: 1rem;
    }

    @media #{ $smalldown } {
        padding: 1rem;
    }

    .image {
        position: relative;
        width: 90px;
        height: 56px;

        @media #{ $mediumup } {
            height: 47px;
        }

        @media #{ $smalldown } {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        img {
            position: relative;
            height: inherit;
        }
    }

    p {
        font-weight: 500;
        margin-left: 0.75rem;
        margin-right: 1.5rem;

        @media #{ $slaptop} {
            margin-right: 2rem;
        }

        @media #{ $smalldown } {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .btn {
        border: 1px solid $f-blue;
        color: $f-blue;
        height: unset;
        padding: 0.5rem 1.5rem;
        font-size: 0.75rem;

        &:hover {
            color: #ffffff;
            background: $f-blue;
        }

        @media #{ $mediumdown } {
            padding: 0.5rem 1rem;
        }

        @media #{ $smalldown } {
            margin-top: 1rem;
        }
    }
}

.gym-banner {
    width: 100%;
    background-color: #f5fbff;
    // padding: 1rem 6rem;
    margin-bottom: 2.5rem;
    border: 1px dashed #005397;
    border-radius: 8px;
    background-image: url("../../assets/svgs/confetti.svg");
    background-repeat: no-repeat;
    background-position-x: right;

    &__container {
        display: flex;
        width: 80%;
        margin: 1rem auto;
        gap: 8px;

        > p {
            color: #000b14;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            > span {
                font-weight: 600;
                color: #005397;
            }
        }

        > .btn {
            color: #005397;
            border: 1px solid #005397;
        }

        > svg {
            color: #005397;
        }
    }
}

.home {
    position: relative;
    width: 100%;

    &__welcome {
        font-size: 2rem;
        margin-bottom: 20px;

        @media #{ $mediumdown } {
            font-size: 1.5rem;
        }

        @media #{ $smalldown } {
            font-size: 1rem;
        }
    }

    &__pass {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 263px;
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        margin-bottom: 30px;

        &-img {
            width: 30%;
            background-image: url("../../assets/images/flance-pass-img.png");
            background-position-y: top;
            background-position-x: center;
            background-size: cover;
            overflow: hidden;
            background-repeat: no-repeat;
            background-color: #fafafa;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;

            @media screen and (max-width: 820px) {
                width: 100%;
                height: 263px;
            }

            @media #{ $smalldown } {
                width: 100%;
                height: 165px;
            }
        }

        &-title {
            font-size: 32px;
            font-weight: 700;
            color: #000b14;
            margin-bottom: 30px;

            @media #{ $largedown } {
                font-size: 24px;
                margin-bottom: 20px;
            }

            @media #{ $mediumdown } {
                font-size: 24px;
                margin-bottom: 20px;
            }

            @media #{ $smalldown } {
                font-size: 16px;
            }

            span {
                color: #005397;
            }
        }

        &-slider {
            width: 70%;
            padding: 30px;

            @media #{ $largedown } {
                padding-left: 20px;
                padding-right: 20px;
            }

            @media screen and (max-width: 820px) {
                width: 100%;
                padding-top: 16px;
            }

            .slick-dots {
                right: 24px;
                width: fit-content;

                @media #{$smalldown} {
                    bottom: 0;
                    right: 0;
                }
            }

            &-container {
                display: flex;
                justify-content: space-between;
                padding-left: 2px;

                img {
                    @media screen and (max-width: 820px) {
                        width: 100px;
                    }

                    @media #{$smalldown} {
                        width: 50px;
                    }
                }
            }

            &-text {
                h4 {
                    font-weight: 600;
                    font-size: 1rem;
                    color: #005397;
                    margin-bottom: 6px;
                }

                p {
                    color: #5c6368;
                    font-size: 0.875rem;
                    margin-bottom: 30px;
                }

                button {
                    width: 182px;
                    height: 44px;
                    @media #{$smalldown} {
                        display: none;
                    }
                }
            }

            &-btn {
                display: none;
                @media #{$smalldown} {
                    display: block;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }

    &__gym {
        display: flex;
        flex-wrap: wrap-reverse;
        width: 100%;
        min-height: 270px;
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        margin-bottom: 30px;
        &-img {
            width: 30%;
            background-image: url("../../assets/images/find-gym.png");
            background-position: center;
            background-size: cover;
            overflow: hidden;
            background-repeat: no-repeat;
            background-color: #fafafa;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;

            @media screen and (max-width: 820px) {
                width: 100%;
                height: 263px;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                border-bottom-right-radius: 0px;
            }

            @media #{ $smalldown } {
                width: 100%;
                height: 165px;
            }
        }

        &-left {
            background-color: #ff8788;
            width: 70%;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            padding: 30px;
            position: relative;
            overflow: hidden;

            @media screen and (max-width: 820px) {
                width: 100%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 20px;
            }

            h1 {
                font-size: 32px;
                font-weight: 700;
                text-align: left;
                color: #000b14;
                margin-bottom: 40px;
                width: 100%;

                @media #{ $largedown } {
                    font-size: 24px;
                }

                @media #{ $mediumdown } {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                @media #{ $smalldown } {
                    font-size: 16px;
                }
            }
        }

        &-action {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            button {
                text-transform: uppercase;
                font-weight: 600;
                color: #3d0814;
                text-decoration-color: transparent;
                transition: text-decoration-color 0.5s;
                font-size: 1rem;
                &:hover {
                    text-decoration-color: #3d0814;
                }

                @media screen and (max-width: 1120px) {
                    margin-bottom: 30px;
                }

                @media screen and (max-width: 820px) {
                    font-size: 14px;
                }
            }
        }

        &-search {
            display: flex;
            width: 60%;
            margin-right: 30px;

            @media screen and (max-width: 1120px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }

            &-input {
                height: 56px;
                background: #f5f5f5;
                border: 1px solid #f5f5f5;
                width: 70%;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                display: flex;
                align-items: center;
                padding-left: 12px;
                transition: background 0.3s;
                &:hover {
                    background: darken($color: #f5f5f5, $amount: 9);
                }

                input {
                    height: 100%;
                    width: 100%;
                    border: none;
                    background: transparent;
                    font-size: 16px;

                    &::placeholder {
                        font-size: 13px;
                    }
                }

                svg {
                    margin-right: 10px;
                }
            }

            button {
                background: #3d0814;
                color: #ff8788;
                height: 56px;
                margin-bottom: 0;
                font-size: 1rem;

                &:hover,
                &:focus {
                    background: darken($color: #3d0814, $amount: 3);
                }

                @media screen and (max-width: 820px) {
                    font-size: 14px;
                }
            }
        }

        &-slider {
            display: inline-block;
            animation: 40s scroll linear infinite;
            &-container {
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                box-sizing: border-box;
                position: absolute;
                bottom: 19px;
            }

            &-item {
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
                white-space: nowrap;
                margin-right: 16px;

                @media screen and (max-width: 820px) {
                    font-size: 12px;
                }

                svg {
                    margin-right: 8px;
                }
            }
        }
    }

    &__banner {
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        gap: 20px;

        // display: grid;
        // grid-template-columns: repeat(1, 1fr);
        // grid-gap: 2.25rem;

        @media #{ $largedown } {
            flex-wrap: wrap;
        }
        &-wrap {
            width: 100%;
            // margin-bottom: 30px;

            // @media screen and (max-width: 1140px) {
            //     width: 100%;
            // }

            // @media screen and (max-width: 820px) {
            // flex: 0 0 100%;
            // }
        }
    }

    &__ref {
        flex-basis: auto;
        flex-grow: 1;
        width: 100%;

        button {
            height: 44px;
            padding-left: 32px;
            padding-right: 32px;
        }

        .banner {
            background-color: #fff3f3;
            border: none;
        }

        &-img {
            width: 219px !important;
            right: 20px !important;

            @media screen and (max-width: 820px) {
                width: 100px !important;
                right: 10px !important;
            }
        }
    }
    &__tax {
        flex-basis: auto;
        flex-grow: 1;
        width: 100%;
        margin-top: 30px;
        button {
            height: 44px;
            padding-left: 32px;
            padding-right: 32px;
        }

        &-img {
            width: 219px !important;
            right: 20px !important;

            @media screen and (max-width: 820px) {
                width: 100px !important;
                right: 10px !important;
            }
        }
    }

    &__benefits {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            @media #{ $largedown } {
                margin-bottom: 12px;
            }
            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 1.5;
                color: #000b14;
                @media #{ $largedown } {
                    font-size: 20px;
                }
            }
        }
    }

    // old stuff

    &__header {
        position: relative;
        margin-bottom: 2.5rem;
        display: flex;
        justify-content: space-between;
        @media #{ $smalldown } {
            flex-direction: column;
            gap: 20px;
        }
        flex-wrap: wrap;

        .welcome {
            position: relative;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 29px;
        }
    }

    &__summary {
        position: relative;

        display: flex;
        flex-direction: column;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media #{ $mediumup } {
            display: flex;
            flex-direction: row;
        }

        @media #{ $slaptop} {
            column-gap: 2.5rem;
        }
    }

    &__summary--card {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        min-height: 190px;

        .__strip {
            position: relative;
            width: fit-content;
            align-self: flex-end;
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            text-transform: uppercase;
            border-radius: 100px 0px 0px 100px;
            text-transform: none;

            .copyBtn {
                width: 14px;
                height: 16px;

                &:hover {
                    cursor: pointer;
                }

                svg {
                    width: inherit;
                    height: inherit;
                    color: $primary;

                    &:hover {
                        color: darken($color: $primary, $amount: 5px);
                    }
                }
            }
        }
    }

    .wallet {
        position: relative;

        @media #{ $mediumup } {
            flex: 1;
        }

        .__strip {
            background-color: #4d0016;
            display: flex;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            align-items: center;
        }

        &__balance {
            position: relative;
            padding-left: 1.5rem;
            padding-right: 1rem;
            margin-top: 1rem;

            @media #{ $mediumup } {
                padding-left: 2rem;
            }

            @media #{ $slaptop } {
                margin-top: 0.5rem;
            }

            &-title {
                position: relative;
                display: flex;
                column-gap: 0.5rem;
                font-size: 0.75rem;
                font-weight: 600;
                text-transform: uppercase;

                .toggle-eye {
                    .eye-icon-alt {
                        width: 18px;
                        height: 16px;
                    }

                    .eye-icon-closed-alt {
                        width: 20px;
                        height: 16px;
                    }

                    svg {
                        color: $primary-light-1;

                        &:hover {
                            color: darken(
                                $color: $primary-light-1,
                                $amount: 5px
                            );
                        }
                    }
                }
            }

            &-value {
                position: relative;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 29px;
                padding-top: 0.5rem;

                @media #{ $largeup } {
                    font-size: 1.75rem;
                    line-height: 34px;
                }
            }
        }

        &__btn-link {
            position: relative;
            margin-top: 1.5rem;
            color: $primary;
            font-weight: 500;

            &:hover {
                color: darken($color: $primary, $amount: 5px);
            }

            svg {
                width: 18px;
                height: 16px;
            }

            a {
                color: inherit;
            }

            @media #{ $slaptop } {
                display: none;
            }
        }

        &__btns {
            position: relative;
            background: #30030d;
            margin-top: 1.5rem;
            padding: 8px 0;
            height: 60px;
            border-radius: 0px 0px 10px 10px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            // column-gap: 2px;
            @media #{ $mediumup } {
                margin-top: 1rem;
            }

            @media #{ $slaptop } {
                grid-template-columns: repeat(3, 1fr);
            }

            .btn {
                color: $primary;

                &:hover {
                    color: darken($color: $primary, $amount: 5px);
                }
            }

            .btn {
                font-size: 0.875rem;
                height: 100%;
                border-radius: unset;
                border-left: 1px solid
                    rgba($color: $primary-light-1, $alpha: 0.3);

                &:first-child {
                    border-left: 0;
                }

                .text-btn {
                    @media #{ $xs-md-down } {
                        display: none;
                    }
                }

                .text-btn-alt {
                    @media #{ $xs-md-up } {
                        display: none;
                    }
                }
            }

            .history-btn {
                display: none;

                @media #{ $slaptop } {
                    display: inline-flex;
                }
            }
        }
    }

    .plan--summary {
        position: relative;
        padding-bottom: 1rem;
        background: $primary-light-1
            url("../../assets/svgs/bg-plan-summary.svg");

        // border: 1px solid $primary-light-1;
        @media #{ $mediumup } {
            flex: 1;
            margin-top: 0;
        }

        .__strip {
            background: $primary-light-2;
            color: $f-blue;
        }

        &__info {
            position: relative;
            padding-left: 1.5rem;
            padding-right: 1rem;

            @media #{ $mediumup } {
                padding-left: 2rem;
            }
        }

        &__name {
            position: relative;
            width: fit-content;
            font-weight: 500;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: #ffffff;
            background: $secondary;
            padding: 0.5rem 1rem;
            border-radius: 100px;
            margin-top: 1.5rem;
        }

        .__plan-amount {
            position: relative;
            display: flex;
            margin-top: 1rem;

            .__value {
                position: relative;
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 29px;
                padding-top: 0.5rem;

                @media #{ $largeup } {
                    font-size: 1.75rem;
                    line-height: 34px;
                }
            }

            .__period {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                color: $gray;
            }
        }

        // &__banner {
        //     position: relative;
        //     min-height: 60px;
        //     background: rgba(255, 255, 255, 0.2);
        //     border-radius: 10px 10px 0px 0px;
        //     color: rgba(255, 255, 255, 1.0);
        //     padding: 0 1.5rem;
        //     display: flex;
        //     row-gap: 1rem;
        //     column-gap: 1rem;
        //     align-items: center;
        //     justify-content: space-between;
        //     flex-wrap: nowrap;
        //     box-sizing: border-box;
        //     @media screen and (max-width: 420px) {
        //         flex-wrap: wrap;
        //         padding: 1rem 1.5rem;
        //     }
        // }

        // &__name {
        //     position: relative;
        //     font-weight: 500;
        //     font-size: 1rem;
        //     line-height: 19px;
        //     text-transform: uppercase;
        // }

        // &__renewal {
        //     position: relative;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     .__date {
        //         position: relative;
        //         margin-left: .625rem;

        //         &-title {
        //             position: relative;
        //             font-weight: 500;
        //             font-size: 0.688rem;
        //             line-height: 16px;
        //             text-transform: uppercase;
        //             color: rgba($color: $off-white, $alpha: .8);
        //         }

        //         &-value {
        //             position: relative;
        //             font-weight: 500;
        //             font-size: .875rem;
        //             line-height: 21px;
        //             color: #FFFFFF;
        //         }
        //     }
        // }

        // &__info {
        //     position: relative;
        //     color: #FFFFFF;
        //     width: 100%;
        //     height: calc(100% - 60px);
        //     display: flex;
        //     align-items: center;
        //     padding: 1rem 0;
        //     @media #{ $smalldown } {
        //         flex-direction: column;
        //         align-items: unset;
        //         padding: 1.5rem 0;
        //     }

        //     .__title {
        //         position: relative;
        //         font-weight: 600;
        //         font-size: .75rem;
        //         line-height: 15px;
        //         text-transform: uppercase;
        //         color: rgba($color: $off-white, $alpha: .8);
        //     }

        //     .__value {
        //         position: relative;
        //         font-style: normal;
        //         font-weight: 600;
        //         font-size: 1.5rem;
        //         line-height: 29px;
        //         padding-top: .5rem;
        //         @media #{ $largeup } {
        //             font-size: 1.75rem;
        //             line-height: 34px;
        //         }
        //     }

        //     .__plan-amount {
        //         position: relative;

        //         .__period {
        //             font-size: .875rem;
        //             font-style: normal;
        //             font-weight: 500;
        //             line-height: 17px;
        //             color: rgba(255, 255, 255, 0.6);
        //         }
        //     }

        //     .__plan-amount, .__plan-net {
        //         flex: 1;
        //         padding: 0 1.5rem;
        //         height: 100%;
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: center;

        //     }

        //     .__plan-net {
        //         border-left: 1px solid rgba($color: $primary-light-1, $alpha: .3);
        //         @media #{ $smalldown } {
        //             border: 0;
        //             padding-top: 1.5rem;
        //         }
        //     }
        // }
    }

    .explore-plans-card {
        position: relative;
        padding: 1.5rem;
        background-color: $primary-light-1;

        // @media #{ $largeup } {
        //     margin-left: 2rem;
        // }

        @media #{ $mediumup } {
            flex: 0.9;
            // margin-left: 1.5rem;
        }

        h3 {
            position: relative;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 24px;
        }

        p {
            position: relative;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.004em;
            color: $gray;
            max-width: 300px;
            width: 100%;
            margin-top: 0.5rem;
        }

        .btn {
            min-width: 160px;
            margin-top: 1.5rem;
        }
    }

    .inital-loan-state {
        background: $primary-light-1
            url("../../assets/images/cash-illustration.png") no-repeat;
        background-position: bottom 0 right 4px;
        background-size: 50%;

        @media #{ $largeup } {
            background-position: center right 3px;
            background-size: 45%;
        }
    }

    .request-card {
        background: $primary-light-1 url("../../assets/svgs/Saly-1.svg")
            no-repeat;
        background-position: bottom right -1.25rem;
        background-size: 50%;

        @media #{ $mediumup } {
            flex: 0.9;
            margin: 0;
            margin-left: 1.875rem;
            margin-left: 1.5rem;
        }

        @media #{ $largeup } {
            background: $primary-light-1 url("../../assets/svgs/Saly-1.svg")
                no-repeat;
            background-position: center right 3px;
            background-size: auto;
            margin-left: 2rem;
        }
    }

    .request-pending-state {
        background-color: $pending-light;
    }

    .pending-loan-state {
        .btn {
            display: none;
        }
    }

    .all--benefits {
        position: relative;
        margin-top: 4rem;

        .benefits {
            position: relative;
            margin-top: 1.5rem;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 1.5rem;

            @media #{ $mediumup } {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem;
            }

            @media #{ $largeup } {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

// Bolt Prehome
.prebolthome {
    &_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 540px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6.25rem;
        text-align: center;

        .image {
            @media #{ $mediumdown } {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

        h3 {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 32px;
            margin-top: 2.5rem;

            @media #{ $mediumdown } {
                font-size: 1.125rem;
                margin-top: 1.5rem;
            }
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.004em;
            color: $gray;
        }
    }
}
