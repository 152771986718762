.banner {
    padding: 30px;
    border: 1px solid #d3d3d3;
    border-radius: 12px;
    height: 100%;
    position: relative;

    @media screen and (max-width: 820px) {
        padding: 20px;
    }

    &__text {
        width: 55%;
        margin-bottom: 44px;

        @media screen and (max-width: 820px) {
            width: 100%;
        }
        h1 {
            font-size: 24px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 12px;
            color: #000b14;
            line-height: 1.5;

            @media screen and (max-width: 820px) {
                font-size: 18px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #5c6368;
            line-height: 1.5;

            @media screen and (max-width: 820px) {
                font-size: 14px;
            }
        }
    }

    button {
        text-transform: uppercase;
        font-weight: 600;
        color: #30030d;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.5s;

        @media screen and (max-width: 820px) {
            font-size: 14px;
        }

        &:hover {
            text-decoration-color: #30030d;
        }

        svg {
            margin-left: 6px;
        }
    }

    img {
        position: absolute;
        right: 5px;
        bottom: 0;
        width: 150px;

        @media screen and (max-width: 820px) {
            width: 100px;
        }
    }
}
