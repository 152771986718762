//INPUTS
//author: @Anisat Yunusa

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea {
    resize: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.form-control {
    display: block;
    width: 100%;
    color: $black;
    font-family: "Inter", sans-serif;
    &:focus {
        border-color: $primary;
        box-shadow: 0 0 0 0.2rem rgba($primary, 10%);
    }
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
    &:last-child {
        margin-bottom: 0;
    }

    #eye-icon {
        width: 16px;
        height: 15px;
    }

    #eye-icon-closed {
        width: 16px;
        height: 13px;
    }

    #toggle-icon,
    .input-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: inherit;
        animation: fadeUp 0.3s linear forwards;
    }
    #toggle-icon {
        svg {
            cursor: pointer;
        }
    }

    @keyframes fadeUp {
        0% {
            transform: translateY(-8px);
            opacity: 0;
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    input,
    select,
    textarea {
        font-family: inherit;
        font-size: 1rem;
        -webkit-appearance: none;
        padding: 1rem 1.25rem;
        border: 1px solid rgba($color: #d3d3d3, $alpha: 0.4);
        border-radius: 8px;
        background: inherit;
        &:focus {
            outline: 0;
        }
        &:disabled {
            background: #f5f5f5;
            cursor: not-allowed;
        }
    }

    select {
        background: url(../../assets/svgs/Down-arrow.svg) right 1.25rem center
            no-repeat;

        .select--placeholder {
            color: rgba($color: $black, $alpha: 0.6) !important;
        }
    }

    .form-control--sm {
        height: 40px;
        padding: 10px 20px;
        font-size: 14px;
    }
}

.error {
    color: red;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-size: 0.75rem !important;
    &::first-letter {
        text-transform: uppercase;
    }
}

.helperText {
    position: relative;
    display: inline-block;
    color: $f-blue;
    font-weight: 500;
    margin-top: 0.75rem;
}

.floating-label {
    label {
        position: absolute;
        left: 1.25rem;
        color: rgba($color: #717171, $alpha: 0.6);
        font-size: 1rem;
        transform-origin: top left;
        text-transform: capitalize;
        transform: translate(0, 20px) scale(1); //move on the y-axis
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        pointer-events: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media screen and (max-width: 575.98px) {
            font-size: 0.875rem;
        }
    }

    .form-control {
        height: 60px;
        &--sm {
            height: 40px;
            padding: 10px 20px !important;
            font-size: 14px;
        }
    }

    /** active label */
    &.active {
        label {
            color: $gray;
            //move the x coordinate and reduce size
            transform: translate(0, 10px) scale(0.75);
        }
    }
    &.active {
        input,
        select,
        textarea {
            padding: 25px 20px 7px 20px;
        }
    }
}

.form-control.textArea {
    height: 100px;
}

// input[type='date'],
// input[type='time'] {
//   	position: relative;
// }

// input[type='date']::-webkit-calendar-picker-indicator,
// input[type='time']::-webkit-calendar-picker-indicator {
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// 	cursor: pointer;
// 	opacity: 0;
// 	z-index: 9999;
// }

.inputgroup {
    display: flex;
    width: 100%;
    &:last-child {
        .form-group {
            margin-bottom: 0;
        }
    }
    .form-group,
    .btn {
        flex: 1;
        &:last-child {
            margin-left: 1.5rem;
            @media #{$mediumdown} {
                margin-left: 1rem;
            }
        }
    }
    @media #{$smalldown} {
        flex-direction: column;

        &:last-child {
            .form-group,
            .btn {
                margin-bottom: 1.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .form-group,
        .btn,
        .card-check {
            &:last-child {
                margin-left: 0;
                margin-bottom: 1.5rem;
            }
        }

        .btn {
            flex: unset;
        }
    }
}
