//BUTTONS
//author: @Anisat Yunusa

.btn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height: 56px;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border-radius: 8px;
    background: transparent;
    box-sizing: border-box;
    appearance: none;
    white-space: nowrap;
    -webkit-appearance: none;
    -o-appearance: none;
    @media #{ $smalldown } {
        font-size: 0.875rem;
    }

    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: no-drop;
        opacity: 0.6;
    }

    &--primary {
        color: $secondary;
        background: $primary;
        transition: all 0.25s ease-in-out;
        &:hover,
        &:focus {
            background-color: darken($primary, 3%);
        }
    }

    &--secondary {
        color: $secondary;
        background: $off-white;
        transition: all 0.25s ease-in-out;
        &:hover,
        &:focus {
            background-color: darken($off-white, 3%);
        }
    }
    &--danger {
        color: #fff;
        background: #e0063c;
        transition: all 0.25s ease-in-out;
        &:hover,
        &:focus {
            background-color: darken(#e0063c, 3%);
        }
    }
    &--transparent {
        color: $secondary;
        background: transparent;
        transition: all 0.25s ease-in-out;
        border: 1px solid rgba(211, 211, 211, 0.6);
        &:hover,
        &:focus {
            background-color: darken($off-white, 3%);
        }
    }
    &--cyan {
        color: #005397;
        background: #eef7ff;
        transition: all 0.25s ease-in-out;
        border: 1px solid #005397;
        &:hover,
        &:focus {
            background-color: darken(#eef7ff, 3%);
        }
    }

    &--link {
        padding: 0;
        height: unset;
        text-transform: unset;
        text-decoration: underline;
        text-underline-position: under;
        font-size: 1rem;
        font-weight: 500;
        line-height: 19px;
        transition: all 0.25s ease-in-out;

        &-secondary {
            color: $secondary;
            &:hover,
            &:focus {
                color: lighten($secondary, 8%);
            }
        }
    }

    &--block {
        width: 100%;
    }
    &--sm {
        height: 40px;
        font-size: 0.875rem;
        line-height: 17px;
    }
    &--md {
        height: 48px;
    }
    &--borderRight {
        border-radius: 0px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

//FILE UPLOAD BUTTONS
.upload-linkbtn {
    position: relative;
    input[type="file"] {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    label {
        position: relative;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
        text-decoration: underline;
        text-underline-position: under;
        cursor: pointer;
        color: $secondary;
        &:hover {
            color: lighten($color: $secondary, $amount: 10%);
        }
    }
}

.upload-well {
    border: 1px dashed $primary;
    border-radius: 8px;
    background: $primary-light-1;
    position: relative;
    // min-height: 71px;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input[type="file"] {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    label {
        position: relative;
        min-height: inherit;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        & > span {
            display: block;
            font-weight: 500;
            font-size: 1rem;
            max-width: 80%;
            // overflow: hidden;
            // text-overflow: ellipsis;

            &:last-child {
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 15px;
                color: $gray;
                margin-top: 0.75rem;
            }

            span {
                text-decoration-line: underline;
                text-underline-position: under;
                font-size: 0.875rem;
                line-height: 20px;
                color: $f-blue;
                cursor: pointer;
            }
        }

        .span_1,
        .span_2 {
            display: block;
        }
    }
}
