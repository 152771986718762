.sidebar {
    width: 120px;
    height: 100vh;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 1px 5px 0px #00000008;
    position: fixed;
    margin-top: 80px;
    display: none;
    background: #fff;
    z-index: 1000;
    transition: width 0.5s;
    @media screen and (min-width: 820px) {
        display: block;
        width: 247px;
        margin-top: 0;
    }

    @media #{$smallup} {
        display: block;
    }

    &.open {
        width: 247px;

        .sidebar__list {
            padding: 2.5rem 19.5px;
        }

        .sidebar__item {
            a {
                padding: 1rem 1.25rem;
                border-radius: 12px;

                svg {
                    margin-right: 1rem;
                    margin-left: 0;
                }
                span {
                    display: block;
                    text-align: right;
                }
            }
        }

        .sidebar__help {
            img {
                margin-right: 6px;
            }

            span {
                display: block;
            }
        }

        .sidebar__control {
            transform: rotate(180deg);
        }
    }

    &__logo {
        box-shadow: 0px 1px 5px 0px #00000008;
        width: 100%;
        padding: 0;
        height: 80px;
        display: none;
        @media screen and (min-width: 820px) {
            display: block;
            padding: 1.56rem 2.5rem;
        }
        img {
            display: none;
            @media screen and (min-width: 820px) {
                display: block;
            }
        }
    }

    &__list {
        padding: 2.5rem 20px;
        @media screen and (min-width: 820px) {
            padding: 2.5rem 19.5px;
        }
    }

    &__item {
        margin-bottom: 6px;

        a {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #5c6368;
            line-height: 20px;
            font-weight: 500;
            border-radius: 12px;
            padding: 16px 2px;

            @media screen and (min-width: 820px) {
                padding: 1rem 1.25rem;
                border-radius: 12px;
            }

            &:hover,
            &.active-link {
                background-color: #fff3f3;
                color: #ff8788;
            }

            svg {
                margin: 0 auto;
                @media screen and (min-width: 820px) {
                    margin-right: 1rem;
                    margin-left: 0;
                }
            }
            span {
                display: none;
                white-space: nowrap;
                @media screen and (min-width: 820px) {
                    display: block;
                }
            }
        }
    }

    &__divider {
        background: radial-gradient(
            50% 50% at 50% 50%,
            #432c2c 0%,
            rgba(80, 28, 28, 0) 100%
        );
        height: 0.5px;
        width: 100%;
        opacity: 0.25;
        position: absolute;
        bottom: 176px;
        @media screen and (min-width: 820px) {
            bottom: 113px;
        }
    }

    &__help {
        position: absolute;
        padding: 6px 12px 6px 12px;
        border-radius: 12px 0px 12px 12px;
        border: 1px solid #ff8788;
        background: #fff3f3;
        display: flex;
        align-items: center;
        left: 50%;
        margin-left: -27px;
        margin-top: 30px;
        bottom: 116px;

        @media screen and (min-width: 820px) {
            bottom: 36px;
            margin-left: -42.5px;
            margin-top: 0;
        }

        img {
            width: 24px;
            height: auto;
            margin-right: 0;

            @media screen and (min-width: 820px) {
                margin-right: 6px;
            }
        }

        span {
            font-size: 14px;
            font-weight: 500;
            color: #5c6368;
            display: none;
            @media screen and (min-width: 820px) {
                display: block;
            }
        }
    }

    &__control {
        width: 100%;
        height: 56px;
        padding: 16px 20px 16px 20px;
        border-radius: 12px;
        background: #ff8788;
        margin-bottom: 40px;
        display: block;
        @media screen and (min-width: 820px) {
            display: none;
        }
    }
}
