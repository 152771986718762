//BENEFICIARY
//author: @Anisat Yunusa

.beneficiaryEmptyState {
    max-width: 520px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.beneficiary-details {
    display: flex;
    justify-content: space-between;

    > p {
        cursor: pointer;
        color: $secondary;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        @media #{ $smalldown } {
            font-size: 0.875rem;
        }
        span {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.beneficiary-empty {
    position: relative;
    text-align: center;

    &__image {
        position: relative;
        max-width: 346px;
        width: 90%;
        margin-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
            margin-left: 1rem;
        }
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 24px;
    }

    p {
        color: $gray;
        line-height: 1.5rem;
        @media #{ $xs-md-down } {
            font-size: 0.875rem;
        }
    }
}

.beneficiary-form {
    position: relative;

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media #{ $xsmalldown } {
            flex-direction: column-reverse;
            .__caption {
                margin-bottom: 1rem;
            }
        }

        .__title {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 22px;
        }

        .__subtext {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 17px;
            color: $gray;
            margin-top: 0.5rem;
        }

        .__caption {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 17px;
            color: $gray;
        }
    }

    .__theForm {
        position: relative;
        margin-top: 2rem;

        .btn-group {
            display: flex;
            @media #{ $xsmalldown } {
                flex-direction: column;
            }

            .btn {
                width: 100%;
                &:first-child {
                    margin-right: 1.5rem;
                }

                @media #{ $xsmalldown } {
                    &:first-child {
                        margin-right: 0;
                    }
                    &:last-child {
                        margin-top: 1.5rem;
                    }
                }
            }
        }

        .selectcard-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 1rem;

            .card-check {
                margin-bottom: 0;
            }

            @media #{ $mediumup } {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1.5rem;
            }
        }

        .info-banner {
            a {
                color: $f-blue;
            }
        }
    }
}

.beneficiaryList {
    position: relative;

    .detail--descrip {
        position: relative;
        border-bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2rem;
        @media #{ $smalldown } {
            flex-direction: column;
            row-gap: 1.5rem;
            align-items: unset;
        }

        .section--header {
            margin-right: 1rem;
        }

        .relation {
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
        }
    }

    &--table {
        position: relative;
        margin-top: 2rem;

        .state {
            position: relative;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 15px;
            text-align: center;
            min-width: 84px;
            height: 20px;
            padding-top: 3px;
            padding-bottom: 3px;
            border-radius: 4px;
            box-sizing: border-box;

            p {
                color: inherit;
            }
        }

        .st-active {
            background-color: #f1fef4;
            color: $success;
        }

        .st-inactive {
            color: $gray;
            background-color: $off-white;
        }

        .st-due {
            color: $danger;
            background-color: $primary-light-1;
        }

        .tablelist_data {
            .state {
                width: max-content;
            }
            @media #{ $xsmalldown } {
                display: block;
            }
        }
    }

    .insurance--details__overview--main {
        .currentPlan {
            height: unset;
            min-height: 190px;
            @extend .card-white;
            padding: 1.5rem;

            @media #{ $largeup } {
                width: 360px;
            }

            @media screen and (min-width: 1024px) {
                height: 190px;
                min-height: unset;
            }

            .left {
                display: flex;
                justify-content: flex-end;
            }

            &__status {
                position: relative;
                padding: 2px;
                min-width: 90px;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
                color: $gray;
                background: $off-white;
                border-radius: 4px;
            }

            .isActive {
                color: $danger;
                background: $primary-light-1;
            }

            .__plan {
                margin-top: 2rem;

                &--title {
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: $gray;
                }

                &--value {
                    position: relative;
                    margin-top: 0.5rem;
                    font-size: 1.75rem;
                    font-weight: 600;
                    line-height: 34px;

                    .__period {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 17px;
                        color: $gray;
                    }
                }
            }
        }

        .policy--details {
            .__hospital-title {
                font-size: 0.688rem;
                line-height: 16px;
                text-transform: uppercase;
                color: $gray;
            }
        }
    }
}

.beneficiary {
    &__wrap {
        margin-top: 16px;
        border-radius: 12px;
        padding: 30px;
        border: 1px solid rgba(211, 211, 211, 0.6);
        overflow: scroll;

        @media screen and (max-width: 820px) {
            padding: 20px;
        }
    }
}
