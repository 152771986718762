.empty__state {
    background: #FFFFFF;
    width: 100%;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    height: 300px;
    margin-top: 16px;
    position: relative;

    &-content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 24px;
        }

        p {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            color: #4F5D75;
            width: 100%;
            max-width: 400px;
        }
    }
}